import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IController } from 'src/app/controllers/interface/IController.interface';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { IDocGroupMappingDto, UTEXService } from 'src/app/core/services/utexapi/utexapi.services';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';

@Injectable({
  providedIn: 'root',
})

export class UTEXDocumentGroupMappingService implements OnDestroy, IController {
  private _entity: BehaviorSubject<IDocGroupMappingDto[]> = new BehaviorSubject<IDocGroupMappingDto[]>(
    undefined
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
    public commonFunctions: CommonFunctions,
    public utexService: UTEXService,
  ) {}

  async search() {
    this._load.next(true);
    this.utexService.getDocGroupMappings()
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this._entity.next(res);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load.next(false);
      },
    });
  }

  public fetchData(override = false): Observable<IDocGroupMappingDto[]> {
    if (this._entity.getValue() === undefined || this._entity.getValue().length === 0 || override) {
      this.search();
    }
    return this._entity;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this._entity.next(undefined);
  }

  public clearEntity(): void {
    this._entity.next(undefined);
  }

  public clearLoad(): void {
    this._load.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
