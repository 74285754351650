export class IamWrapperConstants {
  public static readonly DefaultPageSize = 10;
  public static readonly PerfOptimizedLargePageSize: number = 2000;
  public static readonly MaxPageSize = 9000;
  public static readonly DefaultPageNumber = 1;
  public static readonly DropDownCompaniesCount = 5;
  public static readonly CompaniesPageSize = 5;
  public static readonly UserObjectIdKey = 'userObjectId';
  public static readonly UserCompaniesKey = 'userCompanies';
  public static readonly UnassignedRoleIdsKey: 'unassignedRoleIds';
  public static readonly CompanyDataFetchError = 'Companies data could not be fetched :';
  public static readonly ResourcesDataFetchError = 'Resources/Screens data could not be fetched :';
  public static readonly U2ApplicationName = 'U2';
  public static readonly ApplicationName = 'ApplicationName';
  public static readonly PermissionPrefix = 'is';
  public static readonly RoleUpdateSuccess = 'Role updated successfully';
  public static readonly RoleUpdateFailure = 'Role update failed';
  public static readonly RoleAssignSuccess = 'Role(s) updated successfully';
  public static readonly RoleAssignFailure = 'Role(s) update failed';
  public static readonly UserUnassignSuccess = 'User(s) deleted successfully';
  public static readonly UserAssignSuccess= 'User(s) added successfully';
  public static readonly UserDataFetchError = 'User data could not be fetched :';
  public static readonly UserCreated= 'Users Created.';
  public static readonly UserCreationFailed= 'Some users creation failed.';
  public static readonly DefaultCompanyName: string = 'CDVA';
  public static readonly EmptyString: string = '';
}
