import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { FullDateTimePipe } from '../core/pipes/FullDateTime.pipe';
import { NoCommaPipe } from '../core/pipes/NoComma.pipe';
import { FormatTimestampPipe } from '../core/pipes/formatTimestamp.pipe';
import { LocalDateTimePipe } from '../core/pipes/localDateTime.pipe';
import { MaskPipe } from '../core/pipes/mask.pipe';
import { TrimPipe } from '../core/pipes/trim.pipe';
import { DisableControlDirective } from '../shared/utilities/disable-control/disable-control.directive';
import { AttestationGridComponent } from '../u2-maintenance/cust-emp-relation/attestation-grid/attestation-grid.component';
import { AttestationModalComponent } from '../u2-maintenance/cust-emp-relation/attestation-modal/attestation-modal.component';
import { PcLookupComponent } from '../u2-maintenance/pc-lookup/pc-lookup.component';
import { PtpStatusHistoryComponent } from '../u2-person/ptp/ptp-history/ptp-status-history.component';
import { AdjustmentHistoryPopupComponent } from '../u2-shared/adjustment-history-popup/adjustment-history-popup.component';
import { AddRolePopupComponent } from './add-role-popup/add-role-popup.component';
import { AddressLookupComponent } from './address-lookup/address-lookup.component';
import { AddressPhonePopupComponent } from './address-phone-popup/address-phone-popup.component';
import { AgeniesDynamicControlComponent } from './agenies-dynamic-control/agenies-dynamic-control.component';
import { AlertDialogBoxComponent } from './alert-dialog-box/alert-dialog-box.component';
import { AuthorizationChangeConfirmationComponent } from './authorization-change-confirmation/authorization-change-confirmation.component';
import { AuthorizationLedgerComponent } from './authorization-ledger/authorization-ledger.component';
import { AutoCompleteBoxTemplateComponent } from './autocomplete-box/autocomplete-box-template.component';
import { BatchHistoryComponent } from './batch-history/batch-history.component';
import { BtnWithToolTipComponent } from './btn-with-tool-tip/btn-with-tool-tip.component';
import { CommonConfirmationModalComponent } from './common-confirmation-modal-control/common-confirmation-modal-control.component';
import { CommonGridControlComponent } from './common-grid-control/common-grid-control.component';
import { inCellEnterDirective } from './common-grid-control/directives/incell-enter.directive';
import { AutoCompleteFilterMenuComponent } from './common-grid-control/filter-menus/autocomplete-filter-menu.component';
import { MultiSelectFilterMenuComponent } from './common-grid-control/filter-menus/multiselect-filter-menu.component';
import { SelectFilterMenuComponent } from './common-grid-control/filter-menus/select-filter-menu.component';
import { CommonGridDialogComponent } from './common-grid-dialog/common-grid-dialog.component';
import { CommonHistoryModalComponent } from './common-history-modal/common-history-modal.component';
import { CommonLoadingIndicatorComponent } from './common-loading-indicator/common-loading-indicator.component';
import { CommonLookupControlComponent } from './common-lookup/common-lookup-control.component';
import { CommonModalControlComponent } from './common-modal-control/common-modal-control.component';
import { CommonSmartdateControlComponent } from './common-smartdate-control/common-smartdate-control.component';
import { CommonStatusGridComponent } from './common-status-grid/common-status-grid/common-status-grid.component';
import { CommonTextAreaModalComponent } from './common-textarea-modal/common-textarea-modal.component';
import { CopayDetailsComponent } from './copay-details/copay-details.component';
import { CopayHistoryComponent } from './copay-history-popup/copay-history.component';
import { CoursesLookupComponent } from './courses-lookup/courses-lookup.component';
import { DateHistoryComponent } from './date-history/date-history.component';
import { DeductiontypeLookupComponent } from './deductiontype-lookup/deductiontype-lookup.component';
import { DefaultkendogridtemplateComponent } from './defaultkendogridtemplate/defaultkendogridtemplate.component';
import { RequiredTrimDirective } from './directives/required-trim.directive';
import { Setautofocusdirective } from './directives/setautofocusdirective';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import { ExcelExportActionComponent } from './excel-export-action/excel-export-action.component';
import { AuthCodeFlyoutComponent } from './flyout/auth-code-flyout/auth-code-flyout.component';
import { CopayAmountFlyoutComponent } from './flyout/copay-amount-flyout/copay-amount-flyout.component';
import { ICNFlyoutComponent } from './flyout/icnflyout/icnflyout.component';
import { PaidAmountFlyoutComponent } from './flyout/paid-amount-flyout/paid-amount-flyout.component';
import { ParticipantIdFlyOutComponent } from './flyout/participant-id-fly-out/participant-id-fly-out.component';
import { WriteOffAmountFlyOutComponent } from './flyout/write-off-amount-fly-out/write-off-amount-fly-out.component';
import { InternalAuthorizationComponent } from './internal-authorization/internal-authorization.component';
import { LiveInDateHistoryComponent } from './livein-date-history/livein-date-history.component';
import { AcnLookupComponent } from './lookups/acn-lookup/acn-lookup.component';
import { AdjustmentCodeLookupComponent } from './lookups/adjustment-code-lookup/adjustment-code-lookup.component';
import { AdjustmentOptionLookupComponent } from './lookups/adjustment-option-lookup/adjustment-option-lookup.component';
import { AdjustmentReasonLookupComponent } from './lookups/adjustment-reason-lookup/adjustment-reason-lookup.component';
import { AgeniesLookupComponent } from './lookups/agenies-lookup/agenies-lookup.component';
import { AuthCodeLookupComponent } from './lookups/authCodeLookup/authCodeLookup.component';
import { BatchLookupComponent } from './lookups/batch-lookup/batch-lookup.component';
import { BillCodeLookupComponent } from './lookups/bill-code-lookup/bill-code-lookup.component';
import {
  BillingDashboardTemplateLookupComponent
} from './lookups/billing-dashboard-template-lookup/billing-dashboard-template-lookup.component';
import { CaregiverRoleTypeLookupComponent } from './lookups/caregiver-role-type-lookup/caregiver-role-type-lookup.component';
import { CasemanagerLookupComponent } from './lookups/casemanager-lookup/casemanager-lookup.component';
import { CCNAdjustmentLookupComponent } from './lookups/ccn-adjustment-lookup/ccn-adjustment-lookup.component';
import { CCNLookupComponent } from './lookups/ccnlookup/ccnlookup.component';
import { CgvEmpLookupComponent } from './lookups/cgv-emp-lookup/cgv-emp-lookup.component';
import { ClaimPaymentsBatchLookupComponent } from './lookups/claim-payments-batch-lookup/claim-payments-batch-lookup.component';
import { ClientIdLookupComponent } from './lookups/client-id-lookup/client-id-lookup.component';
import { DateRangeOptionsComponent } from './lookups/date-range-options-lookup/date-range-options.component';
import { DiagnosisCodeLookupComponent } from './lookups/diagnosis-code-lookup/diagnosis-code-lookup.component';
import { EdiConfigurationLookupComponent } from './lookups/edi-configuration-lookup/edi-configuration-lookup.component';
import { EinLookupComponent } from './lookups/ein-lookup/ein-lookup.component';
import { EmpLookupComponent } from './lookups/emp-lookup/emp-lookup.component';
import { EmprLookupComponent } from './lookups/empr-lookup/empr-lookup.component';
import { ExpAccountLookupComponent } from './lookups/exp-account-lookup/exp-account-lookup.component';
import { ExternalCaseManagerLookupComponent } from './lookups/external-case-manager-lookup/external-case-manager-lookup.component';
import { FeeServiceCodeLookupComponent } from './lookups/fee-service-code-lookup/fee-service-code-lookup.component';
import { FundingSourceLookupComponent } from './lookups/funding-source-lookup/funding-source-lookup.component';
import { FundingTypeLookupComponent } from './lookups/funding-type-lookup/funding-type-lookup.component';
import { GlaccountLookupComponent } from './lookups/glaccount-lookup/glaccount-lookup.component';
import { HcpcsLookupComponent } from './lookups/hcpcs-lookup/hcpcs-lookup.component';
import {
  InternalAuthServiceCodeLookupComponent
} from './lookups/internal-auth-servicecode-lookup/internal-auth-servicecode-lookup.component';
import { McnLookupComponent } from './lookups/mcn-lookup/mcn-lookup.component';
import { NotificationMessageLookupComponent } from './lookups/notification-message-lookup/notification-message-lookup.component';
import { OwnerLookupComponent } from './lookups/owner-lookup/owner-lookup.component';
import {
  ParticipantCaregiverRelationLookupComponent
} from './lookups/participant-caregiver-relation-lookup/participant-caregiver-relation-lookup.component';
import { PayorLookupComponent } from './lookups/payor-lookup/payor-lookup.component';
import { PersonLookupComponent } from './lookups/person-lookup/person-lookup.component';
import { PersonRelationshipLookupComponent } from './lookups/person-relationship-lookup/person-relationship-lookup.component';
import { PortalUserLookupComponent } from './lookups/portal-user-lookup/portal-user-lookup.component';
import { PortaluserEmailLookupComponent } from './lookups/portaluser-email-lookup/portaluser-email-lookup.component';
import { PreQualificationClassLookupComponent } from './lookups/prequalification-class-lookup/prequalification-class-lookup.component';
import { ProgramConfigurationlookupComponent } from './lookups/program-configurationlookup/program-configurationlookup.component';
import { ProgramLookupComponent } from './lookups/program-lookup/program-lookup.component';
import { ProgramTypeLookupComponent } from './lookups/programtype-lookup/programtype-lookup.component';
import { QualificationClassLookupComponent } from './lookups/qualification-class-lookup/qualification-class-lookup.component';
import {
  QualificationWithPrereqClassLookupComponent
} from './lookups/qualification-with-prereq-class-lookup/qualification-with-prereq-class-lookup.component';
import { ReceiverLookupComponent } from './lookups/receiver-lookup/receiver-lookup.component';
import { RemarkCodeLookupComponent } from './lookups/remark-code-lookup/remark-code-lookup.component';
import { RepresentativeIdLookupComponent } from './lookups/representative-id-lookup/representative-id-lookup.component';
import { RevAccountLookupComponent } from './lookups/rev-account-lookup/rev-account-lookup.component';
import { ServiceCodeLookupComponent } from './lookups/service-code-lookup/service-code-lookup.component';
import { ServiceTypeLookupComponent } from './lookups/service-type-lookup/service-type-lookup.component';
import { SystemEventLookupComponent } from './lookups/system-event-lookup/system-event-lookup.component';
import { VendorLookupComponent } from './lookups/vendor-lookup/vendor-lookup.component';
import { WaiverCodeLookupModalComponent } from './lookups/waiver-code-lookup-modal/waiver-code-lookup-modal.component';
import { WaiverCodeLookupComponent } from './lookups/waiver-code-lookup/waiver-code-lookup.component';
import { WriteoffErrorTypeLookupComponent } from './lookups/writeoff-errortype-lookup/writeoff-errortype-lookup.component';
import { WriteoffLookupComponent } from './lookups/writeoff-lookup/writeoff-lookup.component';
import { McoPayorMappingComponent } from './mco-payor-mapping/mco-payor-mapping.component';
import { MultiCheckFilterComponent } from './multicheck-filter/multicheck-filter.component';
import { MultiselectCheckboxComponent } from './multiselect-checkbox/multiselect-checkbox.component';
import {
  CaregiverMultiselectLookupComponent
} from './multiselect-lookup/caregiver-multiselect-lookup/caregiver-multiselect-lookup.component';
import {
  CcnstatusMultiselectLookupComponent
} from './multiselect-lookup/ccnstatus-multiselect-lookup/ccnstatus-multiselect-lookup.component';
import { PayorMultiselectLookupComponent } from './multiselect-lookup/payor-multiselect-lookup/payor-multiselect-lookup.component';
import { ProgramMultiselectLookupComponent } from './multiselect-lookup/program-multiselect-lookup/program-multiselect-lookup.component';
import {
  ServeCodeMultiselectLookupComponent
} from './multiselect-lookup/servecode-multiselect-lookup/servecode-multiselect-lookup.component';
import { NotePopupComponent } from './note-popup/note-popup.component';
import { PaymentBatchHistoryComponent } from './payment-batch-history/payment-batch-history.component';
import { PersonProfileCardComponent } from './person-profile-card/person-profile-card.component';
import { PersonTypePopupComponent } from './person-type-popup/person-type-popup.component';
import { PersonTypeStatusHistoryComponent } from './persontype-status-history/persontype-status-history.component';
import { QualificationPopupComponent } from './qualification-popup/qualification-popup.component';
import { QualificationRoleTypeHistoryComponent } from './qualification-role-type-history/qualification-role-type-history.component';
import { RangeUnitModalComponent } from './range-unit-modal/range-unit-modal.component';
import { RelationshipHistoryComponent } from './relationship-history/relationship-history.component';
import { RequestClientRelationshipPopupComponent } from './request-client-relationship-popup/request-client-relationship-popup.component';
import { SsnStatusOverrideComponent } from './ssn-status-override/ssn-status-override.component';
import { StatusDateHistoryComponent } from './status-date-history/status-date-history.component';
import { SubaccountHistoryComponent } from './subaccount-history/subaccount-history.component';
import { TimePopupComponent } from './time-popup/time-popup.component';
import { CommonSmartdatetimeControlComponent } from './common-smartdatetime-control/common-smartdatetime-control.component';
import { BillRateConfigLookupComponent } from './lookups/bill-rate-config-lookup/bill-rate-config-lookup.component';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { CDMSFilterMenuComponent } from './filter-menu/filter-menu.component';
import { TaskLookupComponent } from './lookups/task-lookup/task-lookup.component';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    DialogsModule,
    DropDownsModule,
    ButtonsModule,
    InputsModule,
    FormsModule,
    TooltipModule,
    ReactiveFormsModule,
    DateInputsModule,
    LayoutModule,
    ExcelModule,
    IconsModule,
  ],
  declarations: [
    DefaultkendogridtemplateComponent,
    RequiredTrimDirective,
    Setautofocusdirective,
    ClientIdLookupComponent,
    AuthorizationChangeConfirmationComponent,
    PortalUserLookupComponent,
    PayorLookupComponent,
    BillCodeLookupComponent,
    BatchHistoryComponent,
    ExcelExportActionComponent,
    ServiceCodeLookupComponent,
    ProgramConfigurationlookupComponent,
    ExternalCaseManagerLookupComponent,
    DiagnosisCodeLookupComponent,
    ProgramLookupComponent,
    AcnLookupComponent,
    DateRangeOptionsComponent,
    ServiceCodeLookupComponent,
    NoCommaPipe,
    TrimPipe,
    FormatTimestampPipe,
    LocalDateTimePipe,
    EmpLookupComponent,
    ExpAccountLookupComponent,
    FeeServiceCodeLookupComponent,
    FundingSourceLookupComponent,
    FundingTypeLookupComponent,
    GlaccountLookupComponent,
    ProgramTypeLookupComponent,
    ReceiverLookupComponent,
    VendorLookupComponent,
    HcpcsLookupComponent,
    RevAccountLookupComponent,
    ServiceCodeLookupComponent,
    ProgramConfigurationlookupComponent,
    ExcelExportActionComponent,
    AuthCodeLookupComponent,
    EmpLookupComponent,
    EinLookupComponent,
    AddressLookupComponent,
    EdiConfigurationLookupComponent,
    CCNLookupComponent,
    CCNAdjustmentLookupComponent,
    ParticipantIdFlyOutComponent,
    PaidAmountFlyoutComponent,
    CopayAmountFlyoutComponent,
    WriteOffAmountFlyOutComponent,
    WriteoffLookupComponent,
    ParticipantIdFlyOutComponent,
    BatchLookupComponent,
    ClaimPaymentsBatchLookupComponent,
    ICNFlyoutComponent,
    PaymentBatchHistoryComponent,
    BillingDashboardTemplateLookupComponent,
    ProgramMultiselectLookupComponent,
    PayorMultiselectLookupComponent,
    ServeCodeMultiselectLookupComponent,
    CaregiverMultiselectLookupComponent,
    ServiceTypeLookupComponent,
    AuthCodeFlyoutComponent,
    BtnWithToolTipComponent,
    AgeniesDynamicControlComponent,
    AgeniesLookupComponent,
    McnLookupComponent,
    PersonTypeStatusHistoryComponent,
    PersonProfileCardComponent,
    AddressPhonePopupComponent,
    PersonTypePopupComponent,
    McoPayorMappingComponent,
    MaskPipe,
    FullDateTimePipe,
    DropdownFilterComponent,
    RepresentativeIdLookupComponent,
    CommonGridControlComponent,
    CommonModalControlComponent,
    CommonTextAreaModalComponent,
    CommonHistoryModalComponent,
    CommonConfirmationModalComponent,
    SubaccountHistoryComponent,
    PtpStatusHistoryComponent,
    DeductiontypeLookupComponent,
    AutoCompleteBoxTemplateComponent,
    CommonSmartdateControlComponent,
    CommonSmartdatetimeControlComponent,
    MultiselectCheckboxComponent,
    MultiCheckFilterComponent,
    PersonLookupComponent,
    PortaluserEmailLookupComponent,
    OwnerLookupComponent,
    PcLookupComponent,
    CgvEmpLookupComponent,
    DateHistoryComponent,
    StatusDateHistoryComponent,
    LiveInDateHistoryComponent,
    CcnstatusMultiselectLookupComponent,
    QualificationClassLookupComponent,
    PreQualificationClassLookupComponent,
    QualificationPopupComponent,
    RequestClientRelationshipPopupComponent,
    CaregiverRoleTypeLookupComponent,
    QualificationRoleTypeHistoryComponent,
    SystemEventLookupComponent,
    NotificationMessageLookupComponent,
    InternalAuthorizationComponent,
    CopayDetailsComponent,
    WaiverCodeLookupComponent,
    CasemanagerLookupComponent,
    NotePopupComponent,
    TimePopupComponent,
    CopayHistoryComponent,
    EmprLookupComponent,
    PersonRelationshipLookupComponent,
    AddRolePopupComponent,
    SelectFilterMenuComponent,
    MultiSelectFilterMenuComponent,
    AutoCompleteFilterMenuComponent,
    CommonGridDialogComponent,
    CommonStatusGridComponent,
    AuthorizationLedgerComponent,
    AdjustmentReasonLookupComponent,
    AdjustmentOptionLookupComponent,
    CommonLookupControlComponent,
    RelationshipHistoryComponent,
    AlertDialogBoxComponent,
    AttestationModalComponent,
    AttestationGridComponent,
    InternalAuthServiceCodeLookupComponent,
    AdjustmentCodeLookupComponent,
    RemarkCodeLookupComponent,
    CoursesLookupComponent,
    DisableControlDirective,
    RangeUnitModalComponent,
    DisableControlDirective,
    QualificationWithPrereqClassLookupComponent,
    WaiverCodeLookupModalComponent,
    WriteoffErrorTypeLookupComponent,
    ParticipantCaregiverRelationLookupComponent,
    CommonLoadingIndicatorComponent,
    SsnStatusOverrideComponent,
    inCellEnterDirective,
    AdjustmentHistoryPopupComponent,
    BillRateConfigLookupComponent,
    UserLookupComponent,
    CDMSFilterMenuComponent,
    TaskLookupComponent,
  ],
  exports: [
    DefaultkendogridtemplateComponent,
    RequiredTrimDirective,
    Setautofocusdirective,
    ClientIdLookupComponent,
    AuthorizationChangeConfirmationComponent,
    PortalUserLookupComponent,
    PayorLookupComponent,
    BillCodeLookupComponent,
    BatchHistoryComponent,
    ServiceCodeLookupComponent,
    ProgramConfigurationlookupComponent,
    ExcelExportActionComponent,
    BillCodeLookupComponent,
    ExternalCaseManagerLookupComponent,
    DiagnosisCodeLookupComponent,
    SystemEventLookupComponent,
    NotificationMessageLookupComponent,
    ProgramLookupComponent,
    AcnLookupComponent,
    QualificationClassLookupComponent,
    PreQualificationClassLookupComponent,
    DateRangeOptionsComponent,
    ServiceCodeLookupComponent,
    NoCommaPipe,
    TrimPipe,
    FormatTimestampPipe,
    LocalDateTimePipe,
    EmpLookupComponent,
    ExpAccountLookupComponent,
    FeeServiceCodeLookupComponent,
    FundingSourceLookupComponent,
    FundingTypeLookupComponent,
    GlaccountLookupComponent,
    ProgramTypeLookupComponent,
    ReceiverLookupComponent,
    VendorLookupComponent,
    HcpcsLookupComponent,
    RevAccountLookupComponent,
    ServiceCodeLookupComponent,
    WaiverCodeLookupComponent,
    ProgramConfigurationlookupComponent,
    ExcelExportActionComponent,
    AuthCodeLookupComponent,
    EmpLookupComponent,
    EinLookupComponent,
    AddressLookupComponent,
    EdiConfigurationLookupComponent,
    CCNLookupComponent,
    CCNAdjustmentLookupComponent,
    ParticipantIdFlyOutComponent,
    PaidAmountFlyoutComponent,
    CopayAmountFlyoutComponent,
    WriteOffAmountFlyOutComponent,
    ICNFlyoutComponent,
    WriteoffLookupComponent,
    ClaimPaymentsBatchLookupComponent,
    ParticipantIdFlyOutComponent,
    BatchLookupComponent,
    ICNFlyoutComponent,
    PaymentBatchHistoryComponent,
    BillingDashboardTemplateLookupComponent,
    ProgramMultiselectLookupComponent,
    PayorMultiselectLookupComponent,
    ServeCodeMultiselectLookupComponent,
    CaregiverMultiselectLookupComponent,
    ServiceTypeLookupComponent,
    AuthCodeFlyoutComponent,
    BtnWithToolTipComponent,
    AgeniesDynamicControlComponent,
    AgeniesLookupComponent,
    McnLookupComponent,
    PersonTypeStatusHistoryComponent,
    PersonProfileCardComponent,
    AddressPhonePopupComponent,
    PersonTypePopupComponent,
    McoPayorMappingComponent,
    MaskPipe,
    FullDateTimePipe,
    DropdownFilterComponent,
    RepresentativeIdLookupComponent,
    SubaccountHistoryComponent,
    CommonGridControlComponent,
    CommonModalControlComponent,
    CommonTextAreaModalComponent,
    CommonHistoryModalComponent,
    CommonConfirmationModalComponent,
    CommonSmartdateControlComponent,
    CommonSmartdatetimeControlComponent,
    PtpStatusHistoryComponent,
    AutoCompleteBoxTemplateComponent,
    DeductiontypeLookupComponent,
    MultiselectCheckboxComponent,
    MultiCheckFilterComponent,
    PersonLookupComponent,
    PortaluserEmailLookupComponent,
    OwnerLookupComponent,
    PcLookupComponent,
    CgvEmpLookupComponent,
    DateHistoryComponent,
    CcnstatusMultiselectLookupComponent,
    StatusDateHistoryComponent,
    LiveInDateHistoryComponent,
    QualificationPopupComponent,
    RequestClientRelationshipPopupComponent,
    CaregiverRoleTypeLookupComponent,
    QualificationRoleTypeHistoryComponent,
    InternalAuthorizationComponent,
    CopayDetailsComponent,
    CasemanagerLookupComponent,
    NotePopupComponent,
    TimePopupComponent,
    CopayHistoryComponent,
    EmprLookupComponent,
    PersonRelationshipLookupComponent,
    AddRolePopupComponent,
    CommonGridDialogComponent,
    CommonStatusGridComponent,
    AdjustmentReasonLookupComponent,
    AdjustmentOptionLookupComponent,
    CommonLookupControlComponent,
    RelationshipHistoryComponent,
    AlertDialogBoxComponent,
    AttestationModalComponent,
    AttestationGridComponent,
    InternalAuthServiceCodeLookupComponent,
    AdjustmentCodeLookupComponent,
    RemarkCodeLookupComponent,
    CoursesLookupComponent,
    DisableControlDirective,
    QualificationWithPrereqClassLookupComponent,
    WriteoffErrorTypeLookupComponent,
    ParticipantCaregiverRelationLookupComponent,
    CommonLoadingIndicatorComponent,
    SsnStatusOverrideComponent,
    AdjustmentHistoryPopupComponent,
    BillRateConfigLookupComponent,
    UserLookupComponent,
    CDMSFilterMenuComponent,
    TaskLookupComponent,
  ]
})
export class U2SharedModule { }
