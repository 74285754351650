export class EnumConstants {
    public static EarnType = 'EarnType';
    public static UnitType = 'UnitType';
    public static AuthType = 'AuthType';
    public static CvetBoolean = 'CvetBoolean';
}

export enum BccnStatusEnum {
    InProgress = 1,
    Submitted = 2,
    Billed = 3,
    Rejected = 4,
    Acknowledged = 5,
    PartialAcknowledged = 6,
    Failed = 7
}

export enum ActiveConstant {
    Active = 'AC',
    Inactive = 'IA'
}

export enum ActiveInActiveConstant {
    Active = 'A',
    Inactive = 'I'
}

export class OtFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class CchFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class WwlFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class TaskRequiredFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}
export class AuthAllocationFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class BillableFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class Twenty147ExemptFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class InternalAuthFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class OkToWorkExemptFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class ExternallyReportableFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export class DepartmentPaidHoursFlagConstant {
    public static Yes = 'Y';
    public static No = 'N';
}

export enum AuthType {
    None = 0,
    Internal = 1,
    Participant = 2,
    Both = 3
}

export enum ShiftEntryStatus {
    None = 0,
    Saved = 1,
    Submitted = 2,
    Approved = 3,
    Rejected = 4,
    InProcess = 5,
    ReadyToBePaid = 6,
    Paid = 7,
    Deleted = 8,
    Denied = 9,
    Suspended = 10,
    TestApproved = 11,
    RemovalInProgress = 12,
    TestSubmitted = 13,
    NeverToBePaid = 14
}

export enum PeopleType {
    DesignatedRepresentative = 'designatedrepresentative',
    AgencyAdmin = 'agencyadmin',
    ServiceFacilitator = 'servicefacilitator',
    Participant = 'participant',
    Caregiver = 'caregiver',
    CaregiverProspect = 'caregiverprospect',
    EmployerOfRecord = 'employerofrecord',
    MCO = 'mco',
    ProgramCoordinator = 'coordinator',
    CaseManager = 'casemanager',
}

export enum PersonTypes {
    AgencyAdmin = 'AgencyAdmin',
    DesignatedRepresentative = 'DesignatedRepresentative',
    ServiceFacilitator = 'ServiceFacilitator',
    EmployerOfRecord = 'EmployerOfRecord',
    Participant = 'Participant',
    Caregiver = 'Caregiver',
    CaregiverProspect = 'CaregiverProspect',
    Mco = 'Mco',
    Employer = 'Employer',
    ProgramCoordinator = 'Coordinator',
    CaseManager = 'CaseManager',
    CaregiverGha = 'Caregiver-Admin Time'
}

export enum RelatedPeopleType {
    AgencyAdmin = 'AA',
    DesignatedRepresentative = 'DR',
    ServiceFacilitator = 'SF',
    Participant = 'PTP',
    Caregiver = 'CG',
    EmployerOfRecord = 'EmployerOfRecord',
    Mco = 'MCO',
    Coordinator = 'PC',
    CaseManager = 'CM',
}

export enum RelatedPeople {
    AA = 'Agency Admin',
    DR = 'Designated Representative',
    SF = 'Service Facilitator',
    PTP = 'Participant',
    CG = 'Caregiver',
    EmployerOfRecord = 'Employer Of Record',
    MCO = 'Mco',
    PC = 'Coordinator',
    CM = 'Case Manager',
}

export enum PersonStatus {
    Active = 'AC',
    Inactive = 'IA',
    Incomplete = 'IC',
    OnHold = 'OH',
    Terminated = 'TR'
}

export enum ShiftEntryStatuses {
    None = 'None',
    Saved = 'Saved',
    Submitted = 'Submitted',
    Approved = 'Approved',
    Rejected = 'Rejected',
    InProcess = 'In Process',
    ReadyToBePaid = 'Ready to be Paid',
    Paid = 'Paid',
    Deleted = 'Deleted',
    Denied = 'Denied',
    NeverToBePaid = 'Never to be Paid',
}

export enum SatusCategory {
    Termination = 'Termination',
    Deduction = 'Deduction',
    Ein = 'Ein',
    People = 'People'
}

export enum DeductionTypes {
    FLSUTA = 'FLSUTA',
    MOSUTA = 'MOSUTA',
    SUTA = 'SUTA',
    STATEDC = 'STATE-DC',
    STATEMD = 'STATE-MD',
    STATEMO = 'STATE-MO',
    STATEVA = 'STATE-VA'
}

export enum AuthDetailStatus {
    Active = 'SAAC',
    Inactive = 'SAIA',
    HoldTimeEntry = 'SAHT',
    HoldPayroll = 'SAHP',
    HoldBilling = 'SAHB',
}

export enum DateRanges {
    Custom_Date_Selection = 1,
    Last_30_Days = 30,
    Last_60_Days = 60,
    Last_90_Days = 90,
    Last_180_Days = 180
}

export enum AdjustCCNOptions {
    InsID = 1,
    DiagCode = 2,
    AuthNumber = 3
}

export enum DialogActionEnum {
    Select = 1,
    Cancel = 2,
    Save = 3,
    Delete = 4

}

export enum NoteEntityType {
    AgencyAdmin = 'AgencyAdmin',
    ServiceFacilitator = 'ServiceFacilitator',
    DesignatedRepresentative = 'DesignatedRepresentative',
    MCO = 'MCO',
    EmployerOfRecord = 'EmployerOfRecord',
    Caregiver = 'Caregiver',
    Participant = 'Participant',
    Coordinator = 'Coordinator',
    CaseManager = 'CaseManager',
    CaregiverProspect = 'CaregiverProspect',
    ParticipantEmployerMappingDates = 'ParticipantEmployerMappingDates',
    CaregiverEmployerMappingDates = 'CaregiverEmployerMappingDates',
    EmployerEmployerOfRecordMapping = 'EmployerEmployerOfRecordMapping',
    AuthHeader = 'AuthHeader',
    Mcn = 'Mcn',
    ClaimLines = 'ClaimLines',
    Employer = 'Employer',
    Shift = 'Shift',
    ClaimsProgramConfig = 'ClaimsProgramConfig',
    ServiceTypes = 'ServiceTypes',
    ServiceCodes = 'ServiceCodes',
    BillCodes = 'BillCodes',
    WriteOffCauses = 'WriteOffCauses',
    ProgramTypes = 'ProgramTypes',
    FundingTypes = 'FundingTypes',
    FundingSources = 'FundingSources',
    ClaimBatchDetails = 'ClaimBatchDetails',
    PaymentBatches = 'PaymentBatches',
}

export enum AttestationType {
    Relationship = 'RELATIONSHIP',
    Fica = 'FICA',
    Doc = 'DOC'
}

export enum ModalType {
    Continue = 'continue',
    Confirm = 'confirm',
    Cancel = 'cancel',
    Save = 'save',
    Delete = 'delete',
}

export enum AlertType {
    Error = 'error',
    Warning = 'warning',
}

export enum FilterTypes {
    gte = '>=',
    gt = '>',
    lte = '<=',
    lt = '<',
}
