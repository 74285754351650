import { Component, EventEmitter, Output } from '@angular/core';
import { AppConstants } from './../../core/appConstant';

@Component({
  selector: 'authorization-change-confirmation',
  templateUrl: './authorization-change-confirmation.component.html'
})
export class AuthorizationChangeConfirmationComponent {
  @Output() authorizationChangeConfirmationDataItem = new EventEmitter<boolean>();
  constructor() { }


  public onConfirmationSelect(isSelected: boolean) {
    if (isSelected) {
      AppConstants.formDirty = false;
    }
    this.authorizationChangeConfirmationDataItem.emit(isSelected);
  }

}
