import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class DomSanitization {
    constructor(private domSanitizer: DomSanitizer) {
    }

    public sanitizeListModel(model) {
        if (model != null && model !== '') {
            for (const [key, value] of this.objectEntries(model)) {
                if (this.isArray(value)) {
                    this.sanitizeListModel(value);
                } else if (this.isObject(value)) {
                    this.internalSanitizeListModel(value);
                } else if (this.isString(value)) {
                    this.domSanitizer.sanitize(SecurityContext.HTML, value);
                }
            }
        }
        return model;
    }

    internalSanitizeListModel(value){
        for (const [keyobj, valObj] of this.objectEntries(value)) {
            if (this.isString(valObj)) {
                this.domSanitizer.sanitize(SecurityContext.HTML, valObj);
            } else if (this.isArray(valObj)) {
                this.sanitizeListModel(valObj);
            }
        }
    }


    private isArray(arr): boolean {
        if (arr != null && arr !== '') {
            return arr.constructor.toString().indexOf('Array') > -1;
        } else {
            return false;
        }
    }
    private isString(str): boolean {
        if (str != null && str !== '') {
            return typeof str === 'string';
        } else {
            return false;
        }
    }

    private isObject(obj): boolean {
        if (obj != null && obj !== '') {
            return obj instanceof Object;
        } else {
            return false;
        }
    }

    private objectEntries(obj) {
        if (obj != null && obj !== '') {
            const ownProps = Object.keys( obj);
            let i = ownProps.length;
            const resArray = new Array(i);
            while (i--) {
                resArray[i] = [ownProps[i], obj[ownProps[i]]];
            }
            return resArray;
        }
    }

}
