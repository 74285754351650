<div *ngIf="isEmptyRoute && data ? true : false">
  <div class="breadcrumb-wrap" *ngIf="!IsCustomBreadCrumb">
    <ng-template #recursiveList let-list="list">
      <ng-container *ngIf="list.path === ''; else haveRoute">
        <ng-container *ngIf="list.childern; else lastElement">
          <a id="input-link-mbc-{{ list.name }}" (click)="handleParentClick(list.name)">{{ list.name ? list.name + ' /&nbsp;' : '' }}</a>
        </ng-container>
        <ng-template #lastElement>
          <a>{{ list.name }}</a>
        </ng-template>
      </ng-container>
      <ng-template #haveRoute>
        <a id="input-link-mbc-{{ list.name }}" [routerLink]="list.path">{{ list.name ? list.name + ' /&nbsp;' : '' }}</a>
      </ng-template>
      <ng-container *ngIf="list.childern">
        <ng-container *ngTemplateOutlet="recursiveList; context: { list: list.childern }"></ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngTemplateOutlet="recursiveList; context: { list: data }"></ng-container>
  </div>
  <div class="breadcrumb-wrap" *ngIf="IsCustomBreadCrumb">
    <a data-test-id="input-link-mbc-back" class="back-to-auth-link" (click)="CustomBreadCrumbClick()">{{ CustomBreadCrumbText }}</a>
  </div>
  <h1 class="page-title">{{ data.title }}</h1>
</div>
