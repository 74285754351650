import { Component } from '@angular/core';

@Component({
  selector: 'CDMS-Portal-U2-empty',
  templateUrl: './empty.component.html',
})
export class EmptyComponent {

  constructor() { }

}
