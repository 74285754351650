import { map, Observable } from 'rxjs';
import { CompaniesModel, RoleModel } from '../services/u2api.services';
import { CompanyDto, ResourcesResponseDto } from '../services/rolesapi/rolesapi.services';
import { TransformUtilities } from './utilities.transform';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class CreateRoleTransform {
  static transformOutput(observableResponse: Observable<ResourcesResponseDto[]>, allCompanies: CompanyDto[]): Observable<RoleModel> {
    return observableResponse.pipe(
      map(allResources => {
        const roleModel = new RoleModel({
          roleId: 0,
          roleName: null,
          hasAllCompanies: false,
          canImpersonate: false,
        });
        roleModel.companies = allCompanies.map(
          company =>
            new CompaniesModel({
              companyId: company.companyId,
              companyName: company.companyName,
              isSelected: false,
            }),
        );
        roleModel.screens = TransformUtilities.mapResourcePermissionsToScreens(null, allResources, IamWrapperConstants.U2ApplicationName);
        return roleModel;
      }),
    );
  }
}
