import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ICaregiverProfile } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { ParticipantCaregiverRelationshipModel } from '../../shared/services/models/participantcaregiverrelationshipmodel';

@Injectable({
  providedIn: 'root'
})

export class PersonCommonService {

  constructor() {}
  public readonly getPersonTypeChange: Subject<string> = new Subject<string>();
  public readonly flushProfile$: Subject<string> = new Subject<string>();

  public caregiverLinked: BehaviorSubject<ICaregiverProfile> = new BehaviorSubject(null); // Use to pass caregiver details between pages
  public desiredTab: BehaviorSubject<number> = new BehaviorSubject(null); // Used to pass the desired tab configuration
  public desiredTabParticipant: BehaviorSubject<number> = new BehaviorSubject(null); // Used to pass the desired tab configuration
  public outputRelationship: BehaviorSubject<ParticipantCaregiverRelationshipModel> = new BehaviorSubject(null);
  public outputSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  public notificationsCount = new BehaviorSubject(true);
  currentCount = this.notificationsCount.asObservable();

  relationship: Observable<ParticipantCaregiverRelationshipModel> = this.outputRelationship.asObservable();
  output: Observable<string> = this.outputSubject.asObservable();

  private emitInternalAuthorizationSuccess = new Subject<string>();

  // Observable string streams
  getInternalAuthSaveStatus = this.emitInternalAuthorizationSuccess.asObservable();

  // Service message commands
  setInternalAuthSaveStatus(data: string) { this.emitInternalAuthorizationSuccess.next(data); }

  // Service message commands
  setParticipantCaregiverRelationship(relationship: ParticipantCaregiverRelationshipModel) {
    this.outputRelationship.next(relationship); }
  setPersonTypeChange(change: any) { this.getPersonTypeChange.next(change); }
  send(data: string) {
    this.outputSubject.next(data);
  }

  // Notifications Count
  getUnreadCount(getCount: boolean) {
    this.notificationsCount.next(getCount);
  }
}
