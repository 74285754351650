export class AuthorizationConstants {
    public static get companyCode(): string { return sessionStorage.getItem('CompanyCode'); }
    // ToDo:
    // --> This will be retrieved from cache to assign company code of logged in user.
    // --> Solomon API will just ignores validating 'string', if it's not valid company code like 'CCD'

    public static readonly createAuthorizationSuccess = 'Authorization created successfully with ';

    public static readonly noteRequired = 'Note Required';

    public static readonly authStatusActive = 'AAC';

    public static readonly authStatusInActive = 'AIA';

    public static readonly authStatusHoldTimeEntry = 'HTE';

    public static readonly authStatusHoldPayroll = 'HP';

    public static readonly authStatusHoldBilling = 'HB';

    public static readonly authStatusHoldLabel = 'Hold';

    public static readonly authStatusActiveLabel = 'Active';

    public static readonly authStatusInactiveLabel = 'Inactive';

    public static readonly Multiple = 'Multiple';
}
