import { IamWrapperConstants } from '../iamWrapperConstants';
import { UserRoleMappingRequest } from '../services/u2api.services';
import { RoleIdDto, UserRoleDto } from '../services/usersapi/usersapi.services';
import { AssignRolesByUserIdRequest } from '../models/assign-user-roles-api-requests';
import { UnassignRolesByUserIdRequest } from '../models/unassign-user-roles-api-requests';

export class UpdateRolesByUserIdTransform {
  static transformInput(userRoleMappingRequest: UserRoleMappingRequest): [AssignRolesByUserIdRequest, UnassignRolesByUserIdRequest] {
    const assignRolesRequest = new AssignRolesByUserIdRequest();
    const unassignRolesRequest = new UnassignRolesByUserIdRequest();
    assignRolesRequest.userId = userRoleMappingRequest[IamWrapperConstants.UserObjectIdKey];
    unassignRolesRequest.userId = userRoleMappingRequest[IamWrapperConstants.UserObjectIdKey];
    assignRolesRequest.requestBody = userRoleMappingRequest.roleList.map(
      roleId =>
        new UserRoleDto({
          roleId,
          isPrimary: false,
        }),
    );
    unassignRolesRequest.requestBody = userRoleMappingRequest[IamWrapperConstants.UnassignedRoleIdsKey].map(
      roleId => new RoleIdDto({ roleId }),
    );
    return [assignRolesRequest, unassignRolesRequest];
  }
}
