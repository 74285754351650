export class StorageConstants {
    public static readonly CISearch = 'CISearch';
    public static readonly GoBackTwice = 'GoBackTwice';
    public static readonly EcmSearch = 'EcmSearch';
    public static readonly ProgramSearch = 'ProgSearch';
    public static readonly WriteOffCauseSearch = 'WOSeacrh';
    public static readonly WriteOffErrorTypeSearch = 'WOErrorTypeSearch';
    public static readonly PayorSearch = 'PayorSearch';
    public static readonly ShiftMaintenanceSearch = 'ShiftMaintenanceSearch';
    public static readonly feeTypeSearch = 'FeeTypeSearch';
    public static readonly fundingTypeSearch = 'FundingTypeSearch';
    public static readonly FundingSourceSearch = 'FundingSourceSearch';
    public static readonly BillCodeSearch = 'BillCodeSearch';
    public static readonly accountSearch = 'AccountSearch';
    public static readonly PayrollTemplateSearch = 'PayrollTemplateSearch';
    public static readonly ClientSelectedSearch = 'ClientSelectedSearch';
    public static readonly EmployeeSelectedSearch = 'EmployeeSelectedSearch';
    public static readonly ClaimTemplateSearch = 'ClaimTemplateSearch';
    public static readonly ClaimSummaryInquirySearch = 'ClaimSummaryInquirySearch';
    public static readonly claimsProgramConfigSearch = 'ClaimsProgramConfigSearch';
    public static readonly ClaimBatchSearch = 'ClaimBatchSearch';
    public static readonly FeeRateSearch = 'FeeRateSearch';
    public static readonly ServiceCodeSearch = 'ServiceCodeSearch';
    public static readonly ProgramTypeSearch = 'ProgramTypeSearch';
    public static readonly DiagCodeSearch = 'DiagcodeSearch';
    public static readonly EdiConfigSearch = 'EdiConfigSearch';
    public static readonly RedirectUri = 'adal.login.request';
    public static readonly ClaimDetailsInquiryRedirectUri = 'ClaimDetailsInquiryRedirectUri';
    public static readonly ServiceTypeSearch = 'ServiceTypeSearch';
    public static readonly RepresentativeSelectedRelation = 'RepresentativeSelectedRelation';
    public static readonly ClientRepSelectedRelation = 'ClientRepSelectedRelation';
    public static readonly CaseManagerSelectedRelation = 'CaseManagerSelectedRelation';
    public static readonly CaseManagerSelectedSearch = 'CaseManagerSelectedSearch';
    public static readonly PtpCMSelectedRelation = 'PtpCMSelectedRelation';
    public static readonly AuthorizationsSearch = 'AuthorizationsSearch';
    public static readonly UnappliedPaymentSummaryInquirySearch = 'UnappliedPaymentSummaryInquirySearch';
    public static readonly UnappliedPaymentInquiryRedirectUri = 'UnappliedPaymentInquiryRedirectUri';
    public static readonly PayrollBatchReviewSystem = 'PayrollBatchReviewSystem';
    public static readonly PayrollBatchReviewStart = 'PayrollBatchReviewStart';
    public static readonly PayrollBatchReviewEnd = 'PayrollBatchReviewEnd';
    public static readonly PayrollBatchReviewPayor = 'PayrollBatchReviewPayor';
    public static readonly PayrollBatchReviewCaregiver = 'PayrollBatchReviewCaregiver';
    public static readonly PayrollBatchReviewIsTimeWorked = 'PayrollBatchReviewIsTimeWorked';
    public static readonly PayrollBatchReviewIsAbsence = 'PayrollBatchReviewIsAbsence';
    public static readonly PayrollBatchReviewRunId = 'PayrollBatchReviewRunId';
    public static readonly PayrollBatchReviewStatus = 'PayrollBatchReviewStatus';
    public static readonly IntegrationJobHistoryRequest = 'IntegrationJobHistoryRequest';
}
