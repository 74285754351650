import { map, Observable } from 'rxjs';
import { UserModel } from '../services/u2api.services';
import { UserWithRolesDto, UserWithRolesDtoGridResponseBase } from '../services/usersapi/usersapi.services';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class GetAllUsersWithNoRoleTransform {
  static transformOutput(observableResponse: Observable<UserWithRolesDtoGridResponseBase>): Observable<UserModel[]> {
    return observableResponse.pipe(
      map((response: UserWithRolesDtoGridResponseBase) =>
        response.items
          .filter((user: UserWithRolesDto) => !user.isHavingRole)
          .map((user: UserWithRolesDto) => {
            const userModel = new UserModel({
              // userRoleId is set to 0 to maintain consistency with the old API.
              userRoleId: 0,
              userId: user.userId,
              userName: [user.firstName, user.middleName, user.lastName].join(' '),
              email: user.email,
            });
            userModel[IamWrapperConstants.UserObjectIdKey] = user.objectId;
            return userModel;
          }),
      ),
    );
  }
}
