import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from '../services/app-insights.service';
import { AppConstants } from './../appConstant';
import { UnauthorizeService } from '../services/unauthorize.service';

@Injectable()
export class AccessDeniedInterceptor implements HttpInterceptor {
    // ToDo: PKB -- Handle Service calling error messages
    // @Output() fireUnAuthorize401ErrorMessage: EventEmitter<string> = new EventEmitter();

    constructor(
        private appInsightsService: AppInsightsService,
        private router: Router,
        private readonly unauthorizeService: UnauthorizeService) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
          AppConstants.formDirty = false;
         }, (err: HttpErrorResponse) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    if (AppConstants.pingUserUrl !== err.url) {
                        if (environment.compatibility.useIamApis) {
                            this.unauthorizeService.setUnauthorize();
                        }
                        this.router.navigateByUrl('/unauthorized');
                    }
                }
                if (environment.production) {
                    const error = new Error(err.message);
                    error.name = err.url;
                    this.appInsightsService.logError(error, { data: JSON.stringify({ url: err.url, message: err.message }) });
                }
            }

        }));
    }

}
