<kendo-dialog  title="Select a Company" (close)="onLookupSelect(false)" class="custom-dialog">
  <kendo-grid [loading]="loading" [data]="moreCompaniesGridView" [pageSize]="pageSize" [skip]="skip" [pageable]="pageable"
    [filter]="filter" [scrollable]="'none'" filterable="menu" (filterChange)="filterChange($event)" [sortable]="{
      allowUnsort: 'Enable',
      mode: 'single'
      }"
    [sort]="sort" (sortChange)="sortChange($event)" (pageChange)="pageChange($event)" [selectable]="selectableSettings"
    [kendoGridSelectBy]="'companyId'" [(selectedKeys)]="selectedItemId" class="custom-grid custom-grid-selectable custom-grid-header-noscroll">
    <kendo-grid-checkbox-column [width]="10" ></kendo-grid-checkbox-column>
    <kendo-grid-column field="companyName" [width]="200"  title="Company Name">
        <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filterService]="filterService" [filter]="filter" >
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="companyId"  [width]="200" title="Company ID">
      <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
          <kendo-grid-string-filter-menu [extra]="false" [column]="column" [filterService]="filterService" [filter]="filter" >
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-menu>
        </ng-template>
  </kendo-grid-column>

  </kendo-grid>
  <kendo-dialog-actions>
    <button
      kendoButton
      [disabled]="selectedItemId.length <=0 "
      (click)="onLookupSelect(true)"
      class="cdms-btn-blue"
      data-test-id="cdms-btn-more-companies-confirm"
    >
      OK
    </button>
    <button
      kendoButton
      (click)="onLookupSelect(false)"
      class="cdms-btn-grey"
      data-test-id="cdms-btn-more-companies-cancel"
    >
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
