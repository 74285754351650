import { map, Observable } from 'rxjs';
import { UsersDto } from '../services/rolesapi/rolesapi.services';
import { UserModel } from '../services/u2api.services';

export class GetUsersByRoleIdsTransform {
  static transformInput(roleIds?: string | undefined): number[] | undefined {
    if (roleIds === null || roleIds === undefined) {
      return undefined;
    } else {
      return roleIds.split(',').map(Number);
    }
  }

  static transformOutput(observableResponse: Observable<UsersDto[]>): Observable<UserModel[]> {
    return observableResponse.pipe(map(response => response.map(roleDto => GetUsersByRoleIdsTransform.mapRoleData(roleDto))));
  }

  static mapRoleData(userDto: UsersDto): UserModel {
    const userModel = new UserModel();
    userModel.userId = userDto.userId;
    userModel.userName = [userDto.firstName, userDto.middleName, userDto.lastName].filter(name => name).join(' ');
    userModel.email = userDto.email;
    userModel['objectId'] = userDto.objectId;
    userModel.userRoleId = userDto.userId; // UserRoleId is not required for IAM
    return userModel;
  }
}
