<ul #menu>
    <ng-container *ngFor="let item of items">
        <li *ngIf="!item.childerns.length > 0 && item.display; else branch" class="hasparent nochild">
            <a id="{{item.screenId}}" kendoTooltip position="right" (click)="menuClicked($event, item.screenName);$event.stopPropagation()"
                [routerLink]="item.childerns.length > 0 ? null : item.path" routerLinkActive="selected" class="nochild"
                title="{{item.screenName && item.screenName.length > 25 ? item.screenName:''}}"
                [ngClass]="item.parentId ? 'hasparent' : 'noparent'">
                <span>{{trimScreenName(item.screenName)}}
                    <b class="arrow right"></b>
                </span>
            </a>
        </li>
        <ng-template #branch>
            <li *ngIf="item.display" class="haschild" [ngClass]="item.parentId ? 'hasparent' : 'noparent'">
                <a id="{{item.screenId}}" kendoTooltip position="right" (click)="menuClicked($event, item.screenName);$event.stopPropagation();"
                    [routerLink]="item.childerns.length > 0 ? null : item.path"
                    [routerLinkActive]="item.childerns.length > 0 ? '' : 'selected'"
                    title="{{item.screenName && item.screenName.length > 25 ? item.screenName:''}}"
                    [ngClass]="[item.parentId ? 'hasparent' : 'noparent', item.childerns.length > 0 ? 'haschild' : 'nochild']">
                    <span>{{trimScreenName(item.screenName)}}
                        <b class="arrow right"></b>
                    </span>
                </a>
                <div class="sub-menu">
                    <CDMS-Portal-U2-nav-item #innerPanel [items]="item.childerns"></CDMS-Portal-U2-nav-item>
                </div>
            </li>
        </ng-template>
    </ng-container>
</ul>