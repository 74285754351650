import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { CommonConstants } from 'src/app/shared/constants/common-constants';
import { ModalType } from '../../shared/constants/enum-constants';

@Component({
    selector: 'CDMS-Portal-U2-common-confirmation-modal-control',
    templateUrl: './common-confirmation-modal-control.component.html',
    styleUrls: ['./common-confirmation-modal-control.component.scss'],
})
export class CommonConfirmationModalComponent implements OnInit {

    @Input()
    public set modalType(data: string) {
        this._modalType = ((<any>Object).values(ModalType).includes(data.toLowerCase()))
            ? data.toLowerCase() as ModalType
            : undefined;
        this.setModalType();
    }
    public get modalType() {
        return this._modalType;
    }
    @Input() width = 500;
    @Input() showDialog = false;
    @Input() showAffirmButton = true;
    @Input() showDenyButton = true;
    @Input() title: string;
    @Input() bodyText: string;
    @Input() additionalBodyText: string;
    @Input() warningText: string;
    @Input() showConditionalText: boolean;
    @Input() conditionalText: string;
    @Input() affirmText: string;
    @Input() denyText: string;

    @Output() modalEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();
    @Output() affirmEvent = new EventEmitter();
    @Output() denyEvent = new EventEmitter();

    @ViewChild('modal', { static: true }) modal: DialogModule;
    @ViewChild('affirmButton', { static: true }) affirmButton: ButtonsModule;
    @ViewChild('denyButton', { static: true }) denyButton: ButtonsModule;

    private _modalType: ModalType;

    public defaultTitle: string;
    public defaultBodyText: string;
    public defaultAffirmText: string;
    public defaultDenyText: string;

    constructor(
    ) {}

    // SETUP FUNCTIONS //

    ngOnInit() {
        this.setModalType();
    }

    setModalType(): void {
        switch (this.modalType) {
            case (ModalType.Continue):
                this.setContinueModalDefaults();
                break;
            case (ModalType.Confirm):
                this.setConfirmModalDefaults();
                break;
            case (ModalType.Cancel):
                this.setCancelModalDefaults();
                break;
            case (ModalType.Save):
                this.setSaveModalDefaults();
                break;
            case (ModalType.Delete):
                this.setDeleteModalDefaults();
                break;
            default:
                this.setContinueModalDefaults();
                break;
        }
    }

    setContinueModalDefaults(): void {
        this.defaultTitle = CommonConstants.continue;
        this.defaultAffirmText = CommonConstants.continue;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.continueMessage;
    }

    setConfirmModalDefaults(): void {
        this.defaultTitle = CommonConstants.confirm;
        this.defaultAffirmText = CommonConstants.yes;
        this.defaultDenyText = CommonConstants.no;
        this.defaultBodyText = CommonConstants.confirmMessage;
    }

    setCancelModalDefaults(): void {
        this.defaultTitle = CommonConstants.cancel;
        this.defaultAffirmText = CommonConstants.yes;
        this.defaultDenyText = CommonConstants.no;
        this.defaultBodyText = CommonConstants.cancelMessage;
    }

    setSaveModalDefaults(): void {
        this.defaultTitle = CommonConstants.save;
        this.defaultAffirmText = CommonConstants.save;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.saveMessage;
    }

    setDeleteModalDefaults(): void {
        this.defaultTitle = CommonConstants.delete;
        this.defaultAffirmText = CommonConstants.ok;
        this.defaultDenyText = CommonConstants.cancel;
        this.defaultBodyText = CommonConstants.deleteMessage;
    }

    // EVENT EMITTERS //

    emitModal(button: string): void {
        this.modalEvent.emit(button);
    }
    emitClose(): void {
        this.closeEvent.emit(true);
    }
    emitAffirm(): void {
        this.affirmEvent.emit(true);
    }
    emitDeny(): void {
        this.denyEvent.emit(true);
    }

    // BUTTON FUNCTIONS //

    closeDialog(): void {
        this.emitModal(CommonConstants.close);
        this.emitClose();
    }

    affirmDialog(): void {
        if (this.modalType) { this.emitModal(this.affirmText ?? this.defaultAffirmText); }
        this.emitAffirm();
    }

    denyDialog(): void {
        if (this.modalType) { this.emitModal(this.denyText ?? this.defaultDenyText); }
        this.emitDeny();
    }

}
