import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';


export interface ICCHHistorySearchParameters {
  caregiverEmployerId?: number;
}

export interface ICCHHistorySearchResult {
  cCHStartdate?: Date;
  cCHMinimumHours?: number;
  cCHMaximumHours?: number;
  baseRate?: number;
  totalRate?: number;
}

export interface ICCHRateHistoryHolder {
  totalCareerHours?: number;
  caregiverId?: number;
  caregiverEmployerMappingId?: number;
  caregiverFirstName?: string;
  caregiverLastName?: string;
  cchRateHistory?: RateHistoryBaseResponse[];
}

export interface RateHistoryBaseResponse {
  payrollRecordId?: number;
  payRate?: number;
  baseRateAmount?: number;
  subPayRate?: number;
  isSubPayUnits?: boolean;
  serviceDate?: Date;
  careerHours?: number;
  rateComponents?: RateHistoryModComponent[];
}

export interface RateHistoryModComponent {
  payrollRecordId?: number;
  minHours?: number;
  maxHours?: number;
  payRate?: number;
  subPayRate?: number;
  isSubPayUnits?: boolean;
  serviceDate?: Date;
  startDate?: Date;
  endDate?: Date;
  careerHours?: number;
  careerHourRateModifierId?: number;
  rateComponentAmount?: number;
  rateComponentType?: string;
}


@Injectable({
  providedIn: 'root',
})

export class CCHHistoryService implements OnDestroy {
  private _results: BehaviorSubject<ICCHRateHistoryHolder[]>
  = new BehaviorSubject<ICCHRateHistoryHolder[]>(
    []
  );
  private _parameters: BehaviorSubject<ICCHHistorySearchParameters>
  = new BehaviorSubject<ICCHHistorySearchParameters>(
    {
    }
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
  ) {}


  public getSearchParameters(): Observable<any> {
    return this._parameters;
  }

  public setSearchParameters(data: any) {
    this._parameters?.next(data);
  }

  public getSearchResults(): Observable<any> {
    return this._results;
  }

  public setSearchResults(data: any) {
    this._results?.next(data);
  }

  public clear(): void {
    this.clearEntity();
    this.clearParameters();
  }

  public clearEntity(): void {
    this._results?.next(null);
  }

  public clearParameters(): void {
    this._parameters?.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
