export class ScreenDisableConstants {
  public static readonly CHPRT = 'CHPRT';
  public static readonly CHL = 'CHL';
  public static readonly QUAL = 'QUAL';
  public static readonly ADJAT = 'ADJAT';
  public static readonly NTF = 'NTF';
  public static readonly ATREV = 'ATREV';
  public static readonly PRREV = 'PRREV';
  public static readonly PSCFG = 'PSCFG';
  public static readonly SVCUL = 'SVCUL';
  public static readonly FEA = 'FEA';
  public static readonly PYRTI = 'PYRTI';
  public static readonly UCT = 'UCT';
}
