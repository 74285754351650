import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { IDocumentSummary } from 'src/app/shared/constants/interface-constants';


export interface IQueueSearchParameters {
  docGroup?: IDocumentSummary[];
}


export interface IQueueSearchResult {
  errorId?: number;
  errorCode?: string;
  errorDescription?: string;
  new?: number;
  pending?: number;
}


@Injectable({
  providedIn: 'root',
})

export class QueuePageService implements OnDestroy {
  private _results: BehaviorSubject<IQueueSearchResult[]>
  = new BehaviorSubject<IQueueSearchResult[]>(
    []
  );
  private _parameters: BehaviorSubject<IQueueSearchParameters>
  = new BehaviorSubject<IQueueSearchParameters>(
    {
      docGroup: [],
    }
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
  ) {}


  public getSearchParameters(): Observable<any> {
    return this._parameters;
  }

  public setSearchParameters(data: any) {
    this._parameters?.next(data);
  }

  public getSearchResults(): Observable<any> {
    return this._results;
  }

  public setSearchResults(data: any) {
    this._results?.next(data);
  }

  public clear(): void {
    this._results?.next([]);
    this._parameters?.next({
      docGroup: [],
    });
  }

  public clearEntity(): void {
    this._results?.next(null);
  }

  public clearParameters(): void {
    this._parameters?.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
