import { map, Observable } from 'rxjs';
import { IamWrapperConstants } from '../iamWrapperConstants';
import { ADUserModel } from '../services/u2api.services';
import { UpsertUserCommandV2, UsersResponse } from '../services/usersapi/usersapi.services';

export class UpsertUserV2Transform {
  static transformOutput(observableResponse: Observable<UsersResponse[]>): Observable<string> {
    return observableResponse.pipe(
      map((responses: UsersResponse[]) => {
        const isAllSuccessResponse = !responses.some(x => x?.userId === null || x?.userId === undefined);
        if (isAllSuccessResponse) {
          return IamWrapperConstants.UserCreated;
        } else {
          return IamWrapperConstants.UserCreationFailed;
        }
      }),
    );
  }

  static transformInput(selectedUserData: ADUserModel[]): UpsertUserCommandV2[] {
    return selectedUserData.map(
      user =>
        ({
          email: user.email,
          firstName: user.name,
          lastName: null,
          middleName: null,
          jobTitle: user.jobTitle,
          objectId: user.objectId,
          applicationName: IamWrapperConstants.U2ApplicationName.toLowerCase(),
        } as UpsertUserCommandV2),
    );
  }
}
