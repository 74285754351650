import { map, Observable } from 'rxjs';
import { RolesDto } from '../services/rolesapi/rolesapi.services';

export class GetRoleNameToCopyTransform {
  public static transformOutput(observableResponse: Observable<RolesDto[]>, roleId: number): Observable<string> {
    return observableResponse.pipe(
      map(roles => {
        let copiedRoleName = '';
        let i = 1;
        let roleList: RolesDto | undefined;
        const roleName = roles.find(x => x.roleId === roleId)?.roleName;
        do {
          const suffix = i > 0 ? `_Copy(${i})` : '';
          copiedRoleName = `${roleName}${suffix}`;
          roleList = roles.find(x => x.roleName?.toLowerCase() === copiedRoleName?.toLowerCase());
          i++;
        } while (roleList !== undefined);
        return copiedRoleName;
      }),
    );
  }
}
