import { environment } from './../../environments/environment';
export class AppConstants {
    public static get GraphAPIURL(): string { return 'https://graph.microsoft.com'; }
    public static get AppTitle(): string { return 'CDMS-Portal-U2'; }
    public static sessionTimeOut = 1200; // 20 minutes
    public static sessionLogOut = 1; // 1 sec
    public static pingUserUrl = environment.apiUrl + 'user/pinguser';
    public static get LogOutADURL(): string { return 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri='; }
    public static get noRecordText(): string { return 'No record(s) found'; }
    // eslint-disable-next-line @typescript-eslint/quotes
    public static get id_tokenErrorMessages(): string { return "Cannot match any routes. URL Segment: 'id_token'"; }
    public static get adalTokenExpiryKey(): string { return 'adal.expiration.key' + environment.clientId; }
    public static get adalTokenKey(): string { return 'adal.access.token.key' + environment.clientId; }
    public static get CurrentCompanyCode(): string { return sessionStorage.getItem('CompanyCode'); }
    public static busyLoader = false;
    public static formDirty = false;
    public static showConformation = false;
    public static currentRouteUrl = '';
    public static combobox = 'combobox';
    public static showLoading = false;
    public static anonymousRoutes = ['unauthorized'];
    public static keepAliveIntervel = 300; // 5 minutes
    public static readonly updateUserSession = environment.apiHost + '/api/user/updatesession';
    public static aiSource = 'U2-UI';
    public static tokenRefreshed = 'tokenRefreshed';
    public static svcCode = '{svcCode}';
    public static defaultEndDate = '12/31/2079';
    public static LogoutMSALADURL =
      'https://login.microsoftonline.com/' + environment.tenant + '/oauth2/v2.0/logout?post_logout_redirect_uri=';
}

export class ScreenNames {
    public static readonly FeeTypes = 'Fee Types';
    public static readonly FundingSource = 'Funding Source';
}

export class PermissionScreen {
  public static readonly Create = 'Create';
  public static readonly Update = 'Update';
  public static readonly Read = 'Read';
  public static readonly Delete = 'Delete';
  public static readonly Approve = 'Approve';
}

export enum RefreshAdjustmentActions {
  None = 0,
  ACN = 1 << 0,
  BillRate = 1 << 1,
  PayRate = 1 << 2,
  ChangeTime = 1 << 3,
  RemoveTime = 1 << 4,
  ServiceCode = 1 << 5,
  Hold = 1 << 6,
  UnHold = 1 << 7,
  ServiceDate = 1 << 8,
  PayDate = 1 << 9,
  Time = 1 << 10
}

export enum BcnStatus {
    /// <summary>
    /// The new
    /// </summary>
    New = 1,

    /// <summary>
    /// The in progress
    /// </summary>
    InProgress = 2,

    /// <summary>
    /// The billed
    /// </summary>
    Billed = 3,

    /// <summary>
    /// The hold
    /// </summary>
    Hold = 4
}

