import { map, Observable } from 'rxjs';
import { UsersDto, UsersDtoGridResponseBase } from '../services/rolesapi/rolesapi.services';
import { NonUserModel } from '../services/u2api.services';

export class GetNonRegisteredUsersTransform {
  static transformOutput(observableResponse: Observable<UsersDtoGridResponseBase>): Observable<NonUserModel[]> {
    return observableResponse.pipe(
      map((responses: UsersDtoGridResponseBase) => responses.items.map(roleDto => GetNonRegisteredUsersTransform.mapNonUserData(roleDto))),
    );
  }

  static mapNonUserData(userDto: UsersDto): NonUserModel {
    const nonUserModel = new NonUserModel();
    nonUserModel.userId = userDto.userId;
    nonUserModel.userName = [userDto.firstName, userDto.middleName, userDto.lastName].filter(name => name).join(' ');
    nonUserModel['objectId'] = userDto.objectId;
    return nonUserModel;
  }
}
