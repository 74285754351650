import { map, Observable } from 'rxjs';
import { CompanyDto, RolesDto } from '../services/rolesapi/rolesapi.services';
import { RoleCompanyModel, RoleModel } from '../services/u2api.services';
import { TransformUtilities } from './utilities.transform';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class GetRolesTransform {
  static transformFilteredOutput(
    observableResponse: Observable<RolesDto[]>,
    allCompanies: CompanyDto[],
    currentCompanyCode: string,
  ): Observable<RoleModel[]> {
    const filteredObservableRoles = GetRolesTransform.filterRolesToCurrentApplicationAndCompany(observableResponse, currentCompanyCode);
    return filteredObservableRoles.pipe(
      map(rolesDto =>
        rolesDto.map(roleDto => {
          const roleModel = new RoleModel({ roleId: roleDto.roleId, roleName: roleDto.roleName, canImpersonate: roleDto.canImpersonate });
          roleModel.companies = TransformUtilities.mapCompanyData(roleDto.companies, allCompanies);
          roleModel.hasAllCompanies = TransformUtilities.hasAllCompanies(roleDto.companies, allCompanies);
          return roleModel;
        }),
      ),
    );
  }

  static transformOutput(observableResponse: Observable<RolesDto[]>, allCompanies?: CompanyDto[]): Observable<RoleCompanyModel[]> {
    return observableResponse.pipe(map(response => response.map(roleDto => GetRolesTransform.mapRoleData(roleDto, allCompanies))));
  }

  private static mapRoleData(roleDto: RolesDto, allCompanies?: CompanyDto[]): RoleCompanyModel {
    const roleCompanyModel = new RoleCompanyModel();
    roleCompanyModel.roleId = roleDto.roleId;
    roleCompanyModel.roleName = roleDto.roleName;
    roleCompanyModel.companies = TransformUtilities.mapCompanyData(roleDto.companies, allCompanies);
    roleCompanyModel.hasAllCompanies = TransformUtilities.hasAllCompanies(roleDto.companies, allCompanies);
    return roleCompanyModel;
  }

  private static filterRolesToCurrentApplicationAndCompany(
    roles: Observable<RolesDto[]>,
    currentCompanyCode: string,
  ): Observable<RolesDto[]> {
    return roles.pipe(
      map(roles =>
        roles.filter(
          role =>
            role.applicationName === IamWrapperConstants.U2ApplicationName &&
            role.companies.some(company => company === currentCompanyCode),
        ),
      ),
    );
  }
}
