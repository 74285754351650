import { Injectable } from '@angular/core';
import { FeatureStateService } from '../../shared/services/featureStateService';
import { CompanyDto, ResourcesResponseDto, RolesApiService } from './rolesapi/rolesapi.services';
import {
  CompanyModel,
  ADUserModel,
  FilterRequest,
  GridResponseBaseOfCompanyModel,
  NonUserModel,
  ResponseModel,
  RoleCompanyModel,
  RoleModel,
  RoleService,
  ScreenUser,
  UserModel,
  UserRoleCompany,
  UserRoleMappingRequest,
  UserRoleModel,
  UserService,
  Screens,
} from './u2api.services';
import { Subject, catchError, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import { DeleteRolesTransform } from '../transforms/delete-roles.transform';
import { GetUsersByRoleIdsTransform } from '../transforms/get-users-by-roleids.transform';
import { GetRolesTransform } from '../transforms/get-roles.transform';
import { GetRoleByIdTransform } from '../transforms/get-role-by-id.transform';
import { IamWrapperConstants } from '../iamWrapperConstants';
import { AppInsightsService } from './app-insights.service';
import { CreateRoleTransform } from '../transforms/create-role.transform';
import { GetRoleNameToCopyTransform } from '../transforms/get-rolename-to-copy.transform';
import { UserResponseModel, UsersApiService } from './usersapi/usersapi.services';
import { AddUsersRoleApiRequest, AddUsersRoleTransform } from '../transforms/add-users-role.transform';
import { DeleteUserRolesTransform } from '../transforms/delete-user-role.transform';
import { UpsertRoleTransform } from '../transforms/update-role.transform';
import { AppConstants } from '../appConstant';
import { GetNonRegisteredUsersTransform } from '../transforms/get-nonusers-by-role.transform';
import { GetAllUsersWithNoRoleTransform } from '../transforms/get-all-users-with-no-role.transform';
import { GetUserPermissionsTransforms } from '../transforms/get-user-permissions.transform';
import { GetAllUsersTransform } from '../transforms/get-all-users.transform';
import { GetUserRolesTransform } from '../transforms/get-user-roles.transform';
import { UpdateRolesByUserIdTransform } from '../transforms/update-roles-by-userid.transform';
import { GetUserAccessedCompaniesTransforms } from '../transforms/get-user-accessed-companies.transform';
import { GetOtherAccessedCompaniesTransforms } from '../transforms/get-other-accessed-companies.transform';
import { MsalService } from '@azure/msal-angular';
import { AddLatestAccessedCompanyTransform } from '../transforms/add-latest-accessed-company.transform';
import { UpsertUserV2Transform } from '../transforms/upsert-usersV2.transform';
import { environment } from 'src/environments/environment';
import { ScreenDisableConstants } from '../screenDisableConstants';

@Injectable({
  providedIn: 'root',
})
export class IamWrapperService {
  private allCompanies?: CompanyDto[];
  private allResources?: ResourcesResponseDto[];
  private userAccessedCompany?: CompanyModel[];
  private userData?: UserResponseModel;
  private readonly populateScreenPermissions = new Subject<Screens[]>();

  constructor(
    private rolesApiService: RolesApiService,
    private usersApiService: UsersApiService,
    private u2ApiRoleService: RoleService,
    private u2ApiUserService: UserService,
    private featureState: FeatureStateService,
    private appInsightsService: AppInsightsService,
    private msalService: MsalService,
  ) {}

  get PopulateScreenPermissions() {
    return this.populateScreenPermissions.asObservable();
  }

  get UseIamApis() {
    return this.featureState.useIamApis() || environment.compatibility.useIamApis;
  }

  get UserObjectId() {
    const [account] = this.msalService.instance.getAllAccounts();
    return account?.idTokenClaims?.oid ?? '';
  }

  get UserAccessedCompany(): CompanyModel[] {
    return this.userAccessedCompany;
  }

  set UserAccessedCompany(value: CompanyModel[]) {
    this.userAccessedCompany = value;
  }

  getInitialData() {
    if (this.UseIamApis) {
      const getCompanies$ = this.getCompanies();
      const getResources$ = this.getResources();
      return forkJoin([getCompanies$, getResources$]);
    } else {
      return of([]);
    }
  }

  getCompanies() {
    return this.rolesApiService.getCompanies().pipe(
      tap(companies => (this.allCompanies = companies)),
      catchError(error => {
        this.appInsightsService.trackTrace(IamWrapperConstants.CompanyDataFetchError, error);
        return of([]);
      }),
    );
  }

  getResources() {
    return this.rolesApiService.getResources(IamWrapperConstants.U2ApplicationName.toLowerCase()).pipe(
      tap(resources => (this.allResources = resources)),
      catchError(error => {
        this.appInsightsService.trackTrace(IamWrapperConstants.ResourcesDataFetchError, error);
        return of([]);
      }),
    );
  }

  getUserPermissions(): Observable<ScreenUser> {
    if (this.UseIamApis) {
      let userDataWithPermissions$: Observable<UserResponseModel>;
      if (this.userData === null || this.userData === undefined) {
        userDataWithPermissions$ = this.getUserDataWithPermissions();
      } else {
        userDataWithPermissions$ = of(this.userData);
      }
      return userDataWithPermissions$.pipe(GetUserPermissionsTransforms.transformOutput);
    } else {
      return this.u2ApiUserService.get();
    }
  }

  getUserAccessedCompanies(): Observable<CompanyModel[]> {
    if (this.UseIamApis) {
      let userDataWithPermissions$: Observable<UserResponseModel>;
      if (this.userData === null || this.userData === undefined) {
        userDataWithPermissions$ = this.getUserDataWithPermissions();
      } else {
        userDataWithPermissions$ = of(this.userData);
      }
      return userDataWithPermissions$.pipe(GetUserAccessedCompaniesTransforms.transformOutput);
    } else {
      return this.u2ApiUserService.getUserAccessedCompanies();
    }
  }

  getOtherAccessedCompanies(
    sortColumn?: string | undefined,
    sortDirection?: string | undefined,
    filterColumn?: string | undefined,
    filterValue?: string | undefined,
    pageSize?: number | undefined,
    pageNumber?: number | undefined,
    existingCompanies?: string | undefined,
  ): Observable<GridResponseBaseOfCompanyModel> {
    if (this.UseIamApis) {
      let userDataWithPermissions$: Observable<UserResponseModel>;
      if (this.userData === null || this.userData === undefined) {
        userDataWithPermissions$ = this.getUserDataWithPermissions();
      } else {
        userDataWithPermissions$ = of(this.userData);
      }
      return GetOtherAccessedCompaniesTransforms.transformOutput(userDataWithPermissions$, pageSize, pageNumber, filterColumn, filterValue);
    } else {
      return this.u2ApiUserService.getOtherAccessedCompanies(
        sortColumn,
        sortDirection,
        filterColumn,
        filterValue,
        pageSize,
        pageNumber,
        existingCompanies,
      );
    }
  }

  addLatestAccessedCompany(userRoleCompany: UserRoleCompany): Observable<CompanyModel[]> {
    if (this.UseIamApis) {
      const userObjectId = this.UserObjectId;
      const requestBody = AddLatestAccessedCompanyTransform.transformInput(userRoleCompany, userObjectId, this.userData.companies);
      return this.usersApiService.auditUser(userObjectId, requestBody).pipe(
        switchMap(_ =>
          this.usersApiService.getUserByUserId(userObjectId, IamWrapperConstants.U2ApplicationName.toLowerCase()).pipe(
            tap(newUserData => {
              // Update only the user companies with the new set of companies
              // UserData has screenpermissions which is not included in this API call
              // Hence, a full copy of the user data is NOT to be done
              if (this.userData !== null && this.userData !== undefined) {
                this.userData.companies = newUserData.companies;
              }
            }),
          ),
        ),
        GetUserAccessedCompaniesTransforms.transformOutput,
      );
    } else {
      return this.u2ApiUserService.addLatestAccessedCompany(userRoleCompany);
    }
  }

  getAllUsersWithNoRole(): Observable<UserModel[]> {
    const filterRequest = new FilterRequest({
      pageSize: IamWrapperConstants.MaxPageSize,
      pageNumber: IamWrapperConstants.DefaultPageNumber,
    });

    if (this.UseIamApis) {
      return this.usersApiService
        .getUsersList(IamWrapperConstants.U2ApplicationName.toLowerCase(), filterRequest)
        .pipe(GetAllUsersWithNoRoleTransform.transformOutput);
    } else {
      return this.u2ApiUserService.getAllUsersWithNoRole();
    }
  }

  getRoleNameToCopy(roleId: number): Observable<string> {
    if (this.UseIamApis) {
      return this.rolesApiService
        .getRoles(IamWrapperConstants.U2ApplicationName.toLowerCase())
        .pipe(observableResponse => GetRoleNameToCopyTransform.transformOutput(observableResponse, roleId));
    } else {
      return this.u2ApiRoleService.getRoleNameToCopy(roleId);
    }
  }

  getRole(roleId: string): Observable<RoleModel> {
    if (this.UseIamApis) {
      const roleIdNumber = GetRoleByIdTransform.transformInput(roleId);
      return this.rolesApiService
        .getRoleById(roleIdNumber)
        .pipe(observableResponse => GetRoleByIdTransform.transformOutput(observableResponse, this.allCompanies, this.allResources));
    } else {
      return this.u2ApiRoleService.getRole(roleId);
    }
  }

  getFilteredRoles(): Observable<RoleModel[]> {
    if (this.UseIamApis) {
      return this.rolesApiService
        .getRoles(IamWrapperConstants.U2ApplicationName.toLowerCase())
        .pipe(observableResponse =>
          GetRolesTransform.transformFilteredOutput(observableResponse, this.allCompanies, AppConstants.CurrentCompanyCode),
        );
    } else {
      return this.u2ApiRoleService.getRoles();
    }
  }

  getAllRoles(): Observable<RoleCompanyModel[]> {
    if (this.UseIamApis) {
      return this.rolesApiService
        .getRoles(IamWrapperConstants.U2ApplicationName.toLowerCase())
        .pipe(observableResponse => GetRolesTransform.transformOutput(observableResponse, this.allCompanies));
    } else {
      return this.u2ApiRoleService.getAllRoles();
    }
  }

  getAllUsers(): Observable<UserModel[]> {
    if (this.UseIamApis) {
      const filterRequest: FilterRequest = new FilterRequest({
        pageSize: IamWrapperConstants.PerfOptimizedLargePageSize,
        pageNumber: IamWrapperConstants.DefaultPageNumber,
      });
      return this.usersApiService
        .getUsersList(IamWrapperConstants.U2ApplicationName.toLowerCase(), filterRequest)
        .pipe(GetAllUsersTransform.transformOutput);
    } else {
      return this.u2ApiUserService.getAllUsers();
    }
  }

  getAvilableAssignedRolesByUserId(selectedUser: UserModel): Observable<any> {
    if (this.UseIamApis) {
      const allRoles$ = this.rolesApiService.getRoles(IamWrapperConstants.U2ApplicationName.toLowerCase());
      const userRoles$ = this.usersApiService.getUserRoles(selectedUser[IamWrapperConstants.UserObjectIdKey]);
      return forkJoin([allRoles$, userRoles$]).pipe(
        map(([allRoles, userRoles]) => GetUserRolesTransform.transformOutput(allRoles, userRoles)),
      );
    } else {
      return this.u2ApiRoleService.getAvilableAssignedRolesByUserId(selectedUser.userId);
    }
  }

  checkUsersByRoleIds(roleIds?: string | undefined): Observable<any> {
    if (this.UseIamApis) {
      // multiple api calls are needed
      const filterRequest: FilterRequest = new FilterRequest({
        pageSize: IamWrapperConstants.DefaultPageSize,
        pageNumber: IamWrapperConstants.DefaultPageNumber,
      });
      const roleIdsDto = GetUsersByRoleIdsTransform.transformInput(roleIds);

      // Create an array of observables for each roleId
      const observables = roleIdsDto.map(roleId => this.rolesApiService.getUsersByRole(roleId, filterRequest));

      // Use forkJoin to wait for all observables to complete
      return forkJoin(observables).pipe(
        map(responses => {
          // Check if any response has users
          const hasUsers = responses.some(response => response.length > 0);
          return { hasUsers };
        }),
      );
    } else {
      return this.u2ApiRoleService.checkUsersByRoleIds(roleIds);
    }
  }

  deleteRole(roleIds?: string | undefined): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const roleIdsDto = DeleteRolesTransform.transformInput(roleIds);
      return this.rolesApiService.deleteRole(roleIdsDto).pipe(DeleteRolesTransform.transformOutput);
    } else {
      return this.u2ApiRoleService.deleteRole(roleIds);
    }
  }

  clearUserCache(): Observable<any> {
    if (this.UseIamApis) {
      const userObjectId = this.UserObjectId;
      return this.usersApiService.clearUserCache(userObjectId);
    } else {
      return this.u2ApiUserService.userLogout();
    }
  }

  createRole(): Observable<RoleModel> {
    if (this.UseIamApis) {
      return this.rolesApiService
        .getResources(IamWrapperConstants.U2ApplicationName.toLowerCase())
        .pipe(observableResponse => CreateRoleTransform.transformOutput(observableResponse, this.allCompanies));
    } else {
      return this.u2ApiRoleService.createRole();
    }
  }

  addRole(roleModel: RoleModel): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const addRoleInputDto = UpsertRoleTransform.transformInput(roleModel);
      return this.rolesApiService.upsertRole(addRoleInputDto).pipe(UpsertRoleTransform.transformOutput);
    } else {
      return this.u2ApiRoleService.addRole(roleModel);
    }
  }

  updateRole(roleModel: RoleModel): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const updateRoleInputDto = UpsertRoleTransform.transformInput(roleModel);
      return this.rolesApiService.upsertRole(updateRoleInputDto).pipe(UpsertRoleTransform.transformOutput);
    } else {
      return this.u2ApiRoleService.updateRole(roleModel);
    }
  }

  getUsersByRoleId(roleId: any): Observable<UserModel[]> {
    if (this.UseIamApis) {
      const filterRequest: FilterRequest = new FilterRequest({
        pageSize: IamWrapperConstants.MaxPageSize,
        pageNumber: IamWrapperConstants.DefaultPageNumber,
      });
      return this.rolesApiService.getUsersByRole(roleId, filterRequest).pipe(GetUsersByRoleIdsTransform.transformOutput);
    } else {
      return this.u2ApiRoleService.getUsersByRoleId(roleId);
    }
  }

  addUserRole(userRoleModel: UserRoleModel): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const addUsersRoleApiRequests: AddUsersRoleApiRequest[] = AddUsersRoleTransform.transformInput(userRoleModel);

      const observables = addUsersRoleApiRequests.map(addUserRoleRequest =>
        this.usersApiService.assignRolesToUsers(addUserRoleRequest.userId, addUserRoleRequest.body),
      );

      return forkJoin(observables).pipe(
        map(() => {
          const isSuccess = true;
          const message = IamWrapperConstants.UserAssignSuccess;
          return new ResponseModel({ isSuccess, message });
        }),
      );
    } else {
      return this.u2ApiRoleService.addUserRole(userRoleModel);
    }
  }

  deleteUserRole(userIds: string[], roleId: string, userRoleIds?: string | undefined): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const unassignUsersForRole = DeleteUserRolesTransform.transformInput(userIds, roleId);

      const observables = unassignUsersForRole.map(unassignUserRoleRequest =>
        this.usersApiService.unassignUserRole(unassignUserRoleRequest.userId, unassignUserRoleRequest.body),
      );

      return forkJoin(observables).pipe(
        map(() => {
          const isSuccess = true;
          const message = IamWrapperConstants.UserUnassignSuccess;
          return new ResponseModel({ isSuccess, message });
        }),
      );
    } else {
      return this.u2ApiRoleService.deleteUserRole(userRoleIds);
    }
  }

  getNonUsersByRoleId(roleId: any): Observable<NonUserModel[]> {
    if (this.UseIamApis) {
      const filterRequest: FilterRequest = new FilterRequest({
        filterColumn: IamWrapperConstants.EmptyString,
        filterValue: IamWrapperConstants.EmptyString,
        pageSize: IamWrapperConstants.MaxPageSize,
        pageNumber: IamWrapperConstants.DefaultPageNumber,
      });
      return this.rolesApiService
        .getUnassignedUsersForRole(roleId, IamWrapperConstants.U2ApplicationName.toLowerCase(), filterRequest)
        .pipe(GetNonRegisteredUsersTransform.transformOutput);
    } else {
      return this.u2ApiRoleService.getNonUsersByRoleId(roleId);
    }
  }

  updateAssignedRolesByUserId(userRoleMappingRequest: UserRoleMappingRequest): Observable<ResponseModel> {
    if (this.UseIamApis) {
      const [assignRolesRequest, unassignRolesRequest] = UpdateRolesByUserIdTransform.transformInput(userRoleMappingRequest);
      const assignRolesResponse$ = this.usersApiService.assignRolesToUsers(assignRolesRequest.userId, assignRolesRequest.requestBody);
      const unassignRolesResponse$ = this.usersApiService.unassignUserRole(unassignRolesRequest.userId, unassignRolesRequest.requestBody);
      return forkJoin([assignRolesResponse$, unassignRolesResponse$]).pipe(
        map(() => {
          const isSuccess = true;
          const message = IamWrapperConstants.RoleAssignSuccess;
          return new ResponseModel({ isSuccess, message });
        }),
      );
    } else {
      return this.u2ApiRoleService.updateAssignedRolesByUserId(userRoleMappingRequest);
    }
  }

  createADUsers(selectedUserData: ADUserModel[]): Observable<string> {
    if (this.UseIamApis) {
      const upsertUserV2Input = UpsertUserV2Transform.transformInput(selectedUserData);
      const observables = upsertUserV2Input.map(upsertUserV2Request => this.usersApiService.upsertUserV2(upsertUserV2Request));

      return forkJoin(observables).pipe(UpsertUserV2Transform.transformOutput);
    } else {
      return this.u2ApiUserService.createADUsers(selectedUserData);
    }
  }

  public handleDisplayBasedOnCompanies() {
    const screens = this.getScreenPermissions();
    if (screens) {
      const codesToDisable = this.getListOfCodesToDisable();
      screens.forEach(screen => {
        if (codesToDisable.includes(screen.screenCode)) {
          screen.display = false;
        }
      });
      this.populateScreenPermissions.next(screens);
    }
  }

  private getListOfCodesToDisable() {
    const listOfCodesToDisable: string[] = [];
    if (!this.featureState.isCCHEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.CHPRT);
      listOfCodesToDisable.push(ScreenDisableConstants.CHL);
    }
    if (!this.featureState.isQualificationEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.QUAL);
    }
    if (!this.featureState.isAdminTimeAdjustmentEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.ADJAT);
    }
    if (!this.featureState.isNotificationEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.NTF);
    }
    if (!this.featureState.isCaregiverDemographicsConfirmationEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.ATREV);
    }
    if (!this.featureState.isCaregiverParticipantRelationshipConfirmationEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.PRREV);
    }
    if (!this.featureState.isStatusEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.PSCFG);
    }
    if (!this.featureState.isServiceCodeUsageEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.SVCUL);
    }
    if (!this.featureState.isFeaTransferEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.FEA);
    }
    if (!this.featureState.isPayRatesInjectorEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.PYRTI);
    }
    if (!this.featureState.isUserCreationToolScreenEnabled()) {
      listOfCodesToDisable.push(ScreenDisableConstants.UCT);
    }
    return listOfCodesToDisable;
  }

  private getScreenPermissions() {
    try {
      const item = sessionStorage.getItem('screenpermssions');
      if (item) {
        return <Screens[]>JSON.parse(atob(item));
      }
    } catch {}
  }

  private getUserDataWithPermissions() {
    const userObjectId = this.UserObjectId;
    const includePermissions = true;
    const userDataWithPermissions$ = this.usersApiService
      .getUserByUserId(userObjectId, IamWrapperConstants.U2ApplicationName.toLowerCase(), includePermissions)
      .pipe(tap(userData => (this.userData = userData)));
    return userDataWithPermissions$;
  }
}
