<div *ngIf="showDialog" class="modal-wrapper">
    <kendo-dialog
        *ngIf="showDialog"
        #modal
        class="common-confirmation-modal"
        [title]="title ? title : defaultTitle"
        [width]="width"
        (close)="closeDialog()">
        <p
            *ngIf="warningText !== undefined"
            class="common-confirmation-modal-text-warning">
            {{warningText}}
        </p>
        <p
            *ngIf="showConditionalText === true && conditionalText !== undefined"
            class="common-confirmation-modal-text">
            {{conditionalText}}
        </p>
        <p
            class="common-confirmation-modal-text">
            {{bodyText ? bodyText : defaultBodyText}}
        </p>
        <p
            *ngIf="additionalBodyText !== undefined"
            class="common-confirmation-modal-text">
            {{additionalBodyText}}
        </p>
        <div class="btm-btn-bar">
            <kendo-dialog-actions>
                <button
                    *ngIf="showAffirmButton"
                    kendoButton
                    #affirmButton
                    class="cdms-btn-blue k-button modal-btn"
                    id="affirmBtnCommonConfirmationModal"
                    (click)="affirmDialog()"
                    primary="true"
                    type="button"
                    data-test-id="cdms-btn-common-confirmation-modal-control-affirm"
                >
                    {{affirmText ? affirmText : defaultAffirmText}}
                </button>
                <button
                    *ngIf="showDenyButton"
                    kendoButton
                    #denyButton
                    class="cdms-btn-grey k-button modal-btn m-r-0"
                    id="denyBtnCommonConfirmationModal"
                    (click)="denyDialog()"
                    type="button"
                    data-test-id="cdms-btn-common-confirmation-modal-control-deny"
                >
                    {{denyText ? denyText : defaultDenyText}}
                </button>
            </kendo-dialog-actions>
        </div>
    </kendo-dialog>
</div>
