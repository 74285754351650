import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppConstants } from './../appConstant';
import { PortalUserService } from './../services/userservice.service';

@Injectable()
export class AccessRightsRouteGurd {
  constructor(private _msalGuard: MsalGuard, private userService: PortalUserService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.checkFormDirty(state)) {
      return false;
    }
    return this.userService.checkAcessRights(state.url);
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.checkFormDirty(state)) {
      return false;
    }
    return this.userService.checkAcessRights(state.url);
  }

  checkFormDirty(state: RouterStateSnapshot): boolean {
    if (AppConstants.formDirty) {
      AppConstants.showConformation = true;
      AppConstants.currentRouteUrl = state.url;
      return true;
    }
    return false;
  }
}
