import { map, Observable } from 'rxjs';
import { UserResponseModel } from '../services/usersapi/usersapi.services';
import { GridResponseBaseOfCompanyModel } from '../services/u2api.services';
import { IamWrapperConstants } from '../iamWrapperConstants';
import { TransformUtilities } from './utilities.transform';

export class GetOtherAccessedCompaniesTransforms {
  static transformOutput(
    userData$: Observable<UserResponseModel>,
    pageSize: number,
    pageNumber: number,
    filterColumn: string,
    filterValue: string,
  ): Observable<GridResponseBaseOfCompanyModel> {
    return userData$.pipe(
      map((userData: UserResponseModel) => {
        const companies = TransformUtilities.ExtractUserAccessedCompanies(userData);
        // ignore the first n companies shown in the drop down
        const first: number = IamWrapperConstants.DropDownCompaniesCount + pageSize * (pageNumber - 1);
        const last: number = first + pageSize;
        // if a filter is applied, only show companies that match the filter
        let filteredCompanies = companies.slice(first, last);
        if (filterColumn && filterValue) {
          filteredCompanies = filteredCompanies.filter(company =>
            company[filterColumn].toString().toLowerCase().includes(filterValue.toLowerCase()));
        }
        return new GridResponseBaseOfCompanyModel({
          items: filteredCompanies,
          // TRC is used to control pagination buttons and thus only needs to account for companies not in the dropdown
          totalRecordCount: companies.length - IamWrapperConstants.DropDownCompaniesCount,
          pageNumber,
          pageSize,
        });
      }),
    );
  }
}
