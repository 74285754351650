<div *ngIf="isAuthenticated">
    <div class="container" kendoTooltip showAfter="5000">
        <CDMS-Portal-U2-main-header #header
            [UserName]="userName"
            (sideNavClickChange)="sideNavChangeClick($event)"
            (logOutClickEmitter) = 'logout($event)'
            (companyCodeEmitter)="companyCodeChange($event)"
            (userHasNoAccessEmitter)="userHasNoAccessEvent($event)"
            [companyDatafromSideNav]="latestCompanyAccess"
            *ngIf="IsCompanyContextExistOrUserUnauthorized">
        </CDMS-Portal-U2-main-header>
        <div class="wrapper">
            <CDMS-Portal-U2-main-sidenav
                [class.side-nav-collapsed]="sideNavCollapse"
                [currnetCompany] = "companyCode"
                [hidden]="!showSideNavContent"
                [sidenavToggleClass]="sideNavCollapse"
                (userHasNoAccessEmitter)="userHasNoAccessEvent($event)"
                (firstMenuUrlEmitter)="getFirstMenuUrl($event)"
                (addedLastCompany)="addedLastCompanyEvent($event)">
            </CDMS-Portal-U2-main-sidenav>
            <div class="content" *ngIf="!userHasNoPermissions">
                <CDMS-Portal-U2-main-breadcrumb></CDMS-Portal-U2-main-breadcrumb>
                <div class="page-content roles-content mb-0" [class.side-nav-collapsed]="sideNavCollapse">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <div *ngIf="userHasNoPermissions" class="access-message">
                <p>You do not have access to this page. Please contact system administrator.</p>
            </div>
        </div>
    </div>
</div>

<kendo-dialog *ngIf="sessionExpires" class="custom-dialog custom-dialog-timeout" title="">
    <p class="dialog-text p-0">Your session has been timed out</p>
    <kendo-dialog-actions class="text-center">
        <div>
            <button
                kendoButton
                class="cdms-btn-blue k-button"
                (click)="close('logout')"
                primary="true"
                data-test-id="cdms-btn-close-timeout"
            >
                OK
            </button>
        </div>
    </kendo-dialog-actions>
</kendo-dialog>

<common-loading-indicator [show]="busyLoader || appConst.showLoading"></common-loading-indicator>

<iframe  class="ifame-logout" [attr.src]="url ? url : null" (load)="onFrameLoad()"></iframe>

<authorization-change-confirmation
    *ngIf="appConst.showConformation"
    (authorizationChangeConfirmationDataItem)="onAuthorizationChangeConfirmationDataItem($event)">
</authorization-change-confirmation>
