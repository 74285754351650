import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IController } from 'src/app/controllers/interface/IController.interface';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { IResolutionStatusMappingDto, UTEXService } from 'src/app/core/services/utexapi/utexapi.services';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';


@Injectable({
  providedIn: 'root',
})

export class ResolutionStatusMappingService implements OnDestroy, IController {
  private _entity: BehaviorSubject<IResolutionStatusMappingDto[]>[] = [
    new BehaviorSubject<IResolutionStatusMappingDto[]>(
      undefined
    ),
  ];

  private _parameters: BehaviorSubject<number> =
    new BehaviorSubject<number>(
      undefined
  );

  private _load: BehaviorSubject<boolean>[] = [
    new BehaviorSubject<boolean>(
      false
    ),
  ];

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
    public commonFunctions: CommonFunctions,
    public utexService: UTEXService,
  ) {}

  private checkIndex(index: number) {
    if (this._entity[index] === undefined ) {
      this._entity[index] = new BehaviorSubject<IResolutionStatusMappingDto[]>(
        undefined
      );
    }
    if (this._parameters[index] === undefined ) {
      this._parameters[index] = new BehaviorSubject<any[]>(
        undefined
      );
    }
    if (this._load[index] === undefined ) {
      this._load[index] = new BehaviorSubject<boolean>(
        undefined
      );
    }
  }

  async search(data?: any, index = 0) {
    this.checkIndex(index);
    if (data) {
      this._parameters[index]?.next(data);
    } else if (this._parameters[index].getValue()) {
      data = this._parameters[index].getValue();
    }

    data = this.commonFunctions.removeNull(data);

    this._load[index]?.next(true);
    this.utexService.getResolutionStatusMappings(data)
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this._entity[index]?.next(res);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load[index]?.next(false);
      },
    });
  }

  public fetchData(override = false, index = 0): Observable<IResolutionStatusMappingDto[]> {
    this.checkIndex(index);
    if (this._entity[index].getValue() === undefined || override) {
      this.search(undefined, index);
    }
    return this._entity[index];
  }

  public getSearchParameters(index = 0): Observable<any> {
    this.checkIndex(index);
    return this._parameters[index];
  }

  public setSearchParameters(data: any, index = 0) {
    this.checkIndex(index);
    this._parameters[index]?.next(data);
  }

  public getLoad(index = 0): Observable<boolean> {
    this.checkIndex(index);
    return this._load[index];
  }

  public clear(index = 0): void {
    this._entity[index]?.next(undefined);
    this._parameters[index]?.next(undefined);
  }

  public clearEntity(index = 0): void {
    this._entity[index]?.next(undefined);
  }

  public clearParameters(index = 0): void {
    this._parameters[index]?.next(undefined);
  }

  public clearLoad(index = 0): void {
    this._load[index]?.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
