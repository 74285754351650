export class CommonConstants {
  public static readonly kendoDatePickerMinValue: Date = new Date(1900, 0, 1);
  public static readonly kendoDatePickerMaxValue: Date = new Date(2099, 11, 31);
  public static readonly kendoDatePickerValueIfEmpty = ['MM/dd/yyyy', '0/dd/yyyy', 'MM/0/yyyy', '0/0/yyyy'];
  public static readonly kendoDatepickerPlaceHolder = 'MM/DD/YYYY';
  public static readonly kendoDatetimepickerPlaceHolder = 'MM/dd/yyyy h:mm a';
  public static readonly numOfCharsAfterWhichFilter = 3;
  public static readonly debounceTimeInput = 500;
  public static readonly renderBreadcrumbForIE = 'renderBreadcrumbForIE';
  public static readonly tranlogClientIdname = 'tran-log-clientIdName';
  public static readonly iAuthParentChildId = 'IAuth_Parent_ChildId';

  // Place Holders
  public static readonly phoneNumberMask = '(999) 000-0000';
  public static readonly phoneNumberMaskCustService = '(000) 000-0000';
  public static readonly typeHere = 'Type Here';
  public static readonly numberPlace = '0.00';
  public static readonly markupNumberPlace = '0.0000';
  public static readonly amountPlace = '$0.00';
  public static readonly selectPlace = 'Select';
  public static readonly markupMethod = 'Markup';
  public static readonly commentPlace = ' Type Here (500 characters max)';
  public static readonly amountDecimals = 2;
  public static readonly amountDecimalsPrecise = 6;
  public static readonly amountFormat = 'c2';
  public static readonly decimalFormat = 'n4';
  public static readonly decimalFormatPrecise = 'n6';
  public static readonly timeFormat = 'HH:mm';
  public static readonly ssnMask = '000-00-0000';
  public static readonly einMask = '00-0000000';
  public static readonly notApplicable = 'N/A';

  public static readonly AccountReceivableId = 'AccountReceivableId';
  public static readonly CashAccountId = 'CashAccountId';
  public static readonly CopayAccountId = 'CopayAccountId';
  public static readonly InterestAccountId = 'InterestAccountId';
  public static readonly PayrollOTAccountId = 'PayrollOTAccountId';
  public static readonly RevenueAccountId = 'RevenueAccountId';
  public static readonly MileageExpenseAccountId = 'MileageExpenseAccountId';
  public static readonly PayrollExpenseAccountId = 'PayrollExpenseAccountId';
  public static readonly AccountPayableId = 'AccountPayableId';
  public static readonly AprrovalPending = 'Pending Approval';
  public static readonly Approved = 'Approved';
  public static readonly filenameHeader = 'x-filename';
  public static readonly ExcelContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  public static readonly ClaimTemplateDeleteCondition = 'Please select atleast one Claim Template to delete';
  public static readonly ClaimTemplateDeleteSuccess = 'Claim Template deleted successfully!';
  public static readonly DisassocaiationSuccess = 'Person disassociated successfully!';

  public static readonly ClaimTemplateCopyCondition = 'Please select atleast one Claim Template to copy';
  public static readonly ClaimTemplateOnlyACopyCondition = 'Please select only one Claim Template at a time to copy';
  public static readonly ClaimTemplateAdded = 'Claim Template added successfully!';
  public static readonly ClaimTemplateUpdate = 'Claim Template updated successfully!';
  public static readonly ClaimTemplateCopied = 'Claim Template Copied successfully!';
  public static readonly PayrollTemplateDeleteCondition = 'Please select atleast one Payroll Template to delete';
  public static readonly PayrollTemplateDeleteSuccess = 'Payroll Template deleted successfully!';
  public static readonly PayrollTemplateCopyCondition = 'Please select atleast one Payroll Template to copy';
  public static readonly PayrollTemplateOnlyACopyCondition = 'Please select only one Payroll Template at a time to copy';
  public static readonly PayrollTemplateAdded = 'Payroll Template added successfully!';
  public static readonly PayrollTemplateUpdate = 'Payroll Template updated successfully!';
  public static readonly PayrollTemplateCopied = 'Payroll Template Copied successfully!';
  public static readonly RecordsLockedByShiftAdjustment =
    'Some or all of the PCNs of this batch will be excluded as they are locked by Adjustment Processor.';
  public static readonly EntitiesCreated = 'Entities Created successfully';
  public static readonly EntitiesUpdated = 'Entities Updated successfully';
  public static readonly EntitiesApproved = 'Entities Approved successfully';
  public static readonly ProgramTypeDeletCondition = 'Please select atleast one Program Type to delete';
  public static readonly ProgramTypeAlreadyExist = 'Program Type(s) cannot be deleted as it is already in use';
  public static readonly ProgramTypeDeleted = 'Program Type(s) deleted successfully!';
  public static readonly ProgramTypeAdded = 'Program Type added successfully!';
  public static readonly AddProgramTypeDetails = 'Please add Program Type Details';
  public static readonly ProgramTypeUpdated = 'Program Type updated successfully!';
  public static readonly ProgramTypeApproved = 'Program Type approved successfully!';
  public static readonly CaseLoadReAssigned = 'Case Load Re-Assigned Successfully!';
  public static readonly shiftSourceType = 'WEBTS';
  public static readonly shiftSource = 'U2';
  public static readonly defaultAdjustmentCode = 'NA';
  public static readonly defaultDateFormat = 'MM/dd/yyyy';
  public static readonly dateFormat24yyyy = 'yyyy-MM-dd HH:mm:ss';
  public static readonly AzureContainerNameValidation = '^[a-z0-9](?!.*--)[a-z0-9-]{1,61}[a-z0-9]$';
  public static readonly ProgramEDIConfigCopyCondition = 'Please select atleast one EDI Configuration to copy';
  public static readonly ProgramEDIConfigOnlyACopyCondition = 'Please select only one EDI Configuration at a time to copy';
  public static readonly ProgramEDIConfigDeleteCondition = 'Please select atleast one EDI Configuration to delete';
  public static readonly ProgramEDIConfigDeleteSuccess = 'Program EDI Configuration deleted successfully!';
  public static readonly ProgramEDIConfigAdded = 'EDI Configuration added successfully!';
  public static readonly ProgramEDIConfigUpdate = 'EDI Configuration updated successfully!';
  public static readonly ProgramEDIConfigCopied = 'EDI Configuration Copied successfully!';
  public static readonly ExcelUploadSuccess = 'You will be notified over email when the file is ready for download';
  public static readonly adjustedRows = ' row(s) got adjusted';
  public static readonly notAdjustedRows = ' row(s) not adjusted';
  public static readonly Edited = 'Edited';
  public static readonly Deleted = 'Deleted';
  public static readonly ManualPaymentType = 'Manual';
  public static readonly PaymentBatchStatusInProgress = 'In Progress';
  public static readonly PaymentBatchStatusSubmitted = 'Submitted';
  public static readonly PaymentBatchStatusApproved = 'Approved';
  public static readonly PaymentBatchStatusRejected = 'Rejected';
  public static readonly PaymentAppliedSuccess = 'Payment(s) applied successfully';
  public static readonly PaymentDeletedSuccess = 'Payment(s) deleted successfully';
  public static readonly PaymentUnAppliedSuccess = 'Payment(s) un-applied successfully';
  public static readonly PaymentAddedSuccess = 'Payment batch added successfully';
  public static readonly PaymentUpdateSuccess = 'Payment batch updated successfully';
  public static readonly PaymentBatchRejectSuccess = 'Batch rejected successfully!';
  public static readonly WriteOffExceed = 'Write Off applied amount should be in the range of Over Paid(negative) and Remaining Amounts';
  public static readonly McnWriteOffExceed = 'Write Off applied amount should not be greater than Remaining Amounts';
  public static readonly RemoveWriteOff = 'A batch number has already been selected, do you want to move the write-offs to a new batch?';
  public static readonly blankTab = '_blank';
  public static readonly ServiceTypeDeleteSuccess = 'Service Type(s) deleted successfully!';
  public static readonly ServiceTypeUpdateSuccess = 'Service Type updated successfully!';
  public static readonly ServiceTypeAddSuccess = 'Service Type Added successfully!';
  public static readonly ServiceCodeDeleteSuccess = 'Service code(s) deleted successfully!';
  public static readonly ServiceCodeUpdateSuccess = 'Service code updated successfully!';
  public static readonly PayrollBatchSuccess = 'Request for payroll batch creation submitted successfully!';
  public static readonly ClaimBatchType = 'Claims';
  public static readonly WriteOffPaymentType = 'WriteOff';
  public static readonly UnappliedWriteOffPaymentType = 'Unapplied WriteOff';
  public static readonly ApplicationUpdatePaymentType = 'Application Update';
  public static readonly AutoPaymentType = 'Auto';
  public static readonly WriteOffBatchSaveToolTip = 'Write Off Payment Batch can be submitted only';
  public static readonly AutoManualBatchSaveToolTip = 'Total payment must be greater than or equal to applied amount';
  public static readonly NoChnagesAppliedSaveToolTip = 'No Changes Applied';
  public static readonly ECNMustBeAppliedSaveToolTip = 'ECN must be applied for';
  public static readonly ECNMustBeAppliedToolTip = 'ECN must be applied for edited payments';
  public static readonly CopayApplicationSaveToolTip = 'Copay applications';
  public static readonly PaymentApplicationSaveToolTip = 'Payment applications';
  public static readonly AdjustmentCodeApplicationSaveToolTip = 'Adjustment Code applications';
  public static readonly RemarkCodeApplicationSaveToolTip = 'Remark Code applications';
  public static readonly GroupCodeApplicationSaveToolTip = 'Group Code applications';
  public static readonly AgencyAdminCreatedSuccess = 'Agency Admin created successfully';
  public static readonly AgencyAdminUpdatedSuccess = 'Agency Admin updated successfully';
  public static readonly DRCreatedSuccess = 'Designated Representative created successfully';
  public static readonly DRUpdatedSuccess = 'Designated Representative updated successfully';
  public static readonly Warning = 'c-yellow';
  public static readonly BlueWarning = 'c-blue';
  public static readonly Error = 'error c-red';
  public static readonly Disabled = 'icon-diabled';
  public static readonly Success = 'success';
  public static readonly NoData = 'cicle';
  public static readonly buttonDown = ' float-right m-t-3p';
  public static readonly IncompleteStatus = 'IC';
  public static readonly participantName = 'participantName';
  public static readonly participantId = 'participantId';
  public static readonly NoPayorExist = 'No Payor exist for selected MCO';
  public static readonly caregiverName = 'caregiverName';
  public static readonly caseManagerName = 'casemanagerName';
  public static readonly coordinatorName = 'coordinatorName';
  public static readonly participant = 'participant';
  public static readonly required = '*';
  public static readonly HolidayDetailSuccess = 'The Holiday Detail line(s) has been deleted';
  public static readonly SFCreatedSuccess = 'Service Facilitator added successfully';
  public static readonly SFUpdatedSuccess = 'Service Facilitator updated successfully';
  public static readonly SuccessStatus = 'Success';
  public static readonly WarningStatus = 'Warning';
  public static readonly ErrorStatus = 'Error';
  public static readonly BalancedBatch = 'Batch is balanced.';
  public static readonly UnBalancedBatch = 'Batch is not balanced.';
  public static readonly ImpersonateError = 'Cannot impersonate as user is not onboarded.';
  public static readonly ImpersonatePermissionError = 'Cannot impersonate as user is not permitted.';
  public static readonly ImpersonateErrorFirstLoginIncomplete =
    'Unable to Impersonate. Person must log in to V4 account for impersonation to be enabled for this account.';
  public static readonly ActiveInactive = 'ActiveInactive';
  public static readonly InvalidTotalhours = 'Total Hours must be in 0.25 hours increment.';
  public static readonly TimeOutOfBoundaries = 'Total Hours must be between 0.25 and 24.00';
  public static readonly SSNPermissionError = 'You do not have the permission to view SSN.';
  public static readonly SSNValidationSuccess = 'SSN validated successfully';

  // profile card links constants
  public static readonly Authorization = 'Authorization';
  public static readonly ShiftSummary = 'Shift Summary';
  public static readonly Rates = 'Rates';
  public static readonly Relationships = 'Relationships';
  public static readonly ParticipantTask = 'Participant Task';
  public static readonly Agencies = 'Agencies';
  public static readonly Payors = 'Payors';
  public static readonly CaseLoad = 'Caseloads';
  public static readonly AuthAllocation = 'Auth Allocation';
  public static readonly SSNValidation = 'SSN Validation';

  public static readonly Red = 'Red';
  public static readonly Yellow = 'Yellow';
  public static readonly Green = 'Green';
  public static readonly Blue = 'Blue';
  // Person Types Display Text
  public static readonly EmployerOfRecord = 'Employer Of Record';
  public static readonly Participant = 'Participant';
  public static readonly Caregiver = 'Caregiver';
  public static readonly CaregiverProspect = 'Caregiver Prospect';
  public static readonly DR = 'Designated Representative';
  public static readonly SF = 'Service Facilitator';
  public static readonly AA = 'Agency Admin';
  public static readonly MCO = 'MCO';
  public static readonly PC = 'Coordinator';
  public static readonly CaseManager = 'Case Manager';

  // Person Types internal keys
  public static readonly employerOfRecord = 'EmployerOfRecord';
  public static readonly DesignatedRepresentative = 'DesignatedRepresentative';
  public static readonly ServiceFacilitator = 'ServiceFacilitator';
  public static readonly AgencyAdmin = 'AgencyAdmin';
  public static readonly ManagedCareOrganization = 'ManagedCareOrganization';
  public static readonly ProgramCoordinator = 'Coordinator';
  public static readonly CaregiverProspectKey = 'CaregiverProspect';
  public static readonly CaseManagerKey = 'CaseManager';
  public static readonly EOR = 'EOR';

  public static readonly Role = 'Role';

  public static readonly employerOfRecordName = 'employerOfRecordName';
  public static readonly DrName = 'drName';
  public static readonly CgvSavedSuccess = 'Caregiver created successfully';
  public static readonly PCSavedSuccess = 'Coordinator created successfully';
  public static readonly StatusChangeReasonRequired = 'Status change reason is required';

  // Billing Dashboard
  public static readonly billingDashbaordUpdated = 'Billing dashboard template updated successfully!';
  public static readonly billingDashbaordCreated = 'Billing dashboard template created successfully!';

  // Mco Person Type
  public static readonly mcoPersonTypeUpdated = 'Manage care organization updated successfully!';
  public static readonly mcoPersonTypeCreated = 'Manage care organization created successfully!';
  public static readonly mcoPersonTypeReasonRequired = 'Reason is required';

  // Agreement
  public static readonly AgreementUpdated = 'Agreement updated successfully!';
  public static readonly AgreementCreated = 'Agreement created successfully!';
  public static readonly SubAccountDelimiter = 'C';
  public static readonly BudgetSummaryStatusControl = 'budgetSummaryStatus';
  public static readonly BudgetSummaryDefaultStatus = 'SAAC';
  public static readonly BudgetSummaryDeleteStatus = 'SADL';
  public static readonly BudgetSummaryHoldIdentifier = 'Hold';

  public static readonly PtpntRepRelUpdateSuccess = 'Participant Representative relation updated successfully!';
  public static readonly PtpntRepRelAddSuccess = 'Participant Representative relation created successfully!';
  public static readonly PtpntCmgrRelUpdateSuccess = 'Participant Case Manager relation updated successfully!';
  public static readonly PtpntCmgrRelAddSuccess = 'Participant Case Manager relation created successfully!';
  public static readonly CustomerService = 'Customer Service';
  public static readonly DefaultAgreementStatus = 'AAC';
  public static readonly CgvUpdatedSuccess = 'Caregiver updated successfully';
  public static readonly CaregiverProspectCreated = 'Caregiver Prospect created successfully';
  public static readonly CaregiverProspectUpdated = 'Caregiver Prospect updated successfully';
  public static readonly CgvWWLUpdatedSuccessfully = 'Caregiver WWL Updated successfully';
  public static readonly IntAuthUpdatedSuccessfully = 'Internal Authorization Updated successfully';
  public static readonly ParticipantTaskSuccess = 'Participant Task updated successfully.';
  public static readonly ServiceCodeTaskSuccess = 'Service Code Task updated successfully';
  public static readonly PCUpdatedSuccess = 'Coordinator updated successfully';
  public static readonly PtpSavedSuccess = 'Participant created successfully';
  public static readonly PtpUpdatedSuccess = 'Participant updated successfully';
  public static readonly CmSavedSuccess = 'Case Manager created successfully';
  public static readonly CmUpdatedSuccess = 'Case Manager updated successfully';
  public static readonly PaidStatus = 'Paid';
  public static readonly EmployerDeleteCondition = 'Please select atleast one Employer to delete';
  public static readonly EmployerDeleteSuccess = 'Employer(s) deleted successfully!';
  public static readonly EmployerUpdatedSuccess = 'Employer updated successfully';
  public static readonly EmployerCreatedSuccess = 'Employer created successfully';
  public static readonly EmployerEntityType = 'Employer';
  public static readonly HistoryType = 'Termination';
  public static readonly EmployerExistsServerMessage = 'Employer Exists';
  public static readonly EmployerExists = 'An Employer already exists with the provided EIN value.';
  public static readonly EinRequired = 'EIN is required';
  public static readonly EmployerSsnExistServerMessage = 'Duplicate Employer SSN';
  public static readonly EmployerSsnExists = 'Employer with this SSN/ITIN already exists';

  public static readonly EmployerOfRecordUpdatedSuccess = 'Employer Of Record updated successfully';
  public static readonly EmployerOfRecordCreateSuccess = 'Employer Of Record created successfully';
  public static readonly EmployerOfRecordEmployerSaveNotAllowed = 'EmployerOfRecordEmployerSaveNotAllowed';
  public static readonly SameDeductionExists = 'Deduction Type already exists';
  public static readonly DeductionTypeOverlaps = 'Deduction Type already exists with overlapping dates';
  public static readonly MCOType = 'Managed Care Organization';

  public static readonly UserDeleted = 'Account deleted successfully';
  public static readonly UserDeleteFail = 'could not delete account please try later';
  public static readonly MaxOnboardMessage = 'Maximum of 500 users can be onboarded at a time.';
  public static readonly AccountonboardSuccess = 'Person(s) onboarded successfully';
  public static readonly AccountonboardFail = 'Could not onboard person(s) please try later';
  public static readonly ForceOnboardSuccess = 'Person onboarding started successfully';
  public static readonly StatusPopup = 'Status';
  public static readonly DeductionIdPrefix = '999999999';
  public static readonly ColumnWidth8 = 'col-md-8';
  public static readonly ColumnWidth12 = 'col-md-12';
  public static readonly PrimaryAddressSuccess = 'Primary address set successfully!';
  public static readonly PrimaryPhoneSuccess = 'Primary phone set successfully!';
  public static readonly Phone = 'Phone';
  public static readonly Address = 'Address';

  public static readonly FirstName = 'First Name';
  public static readonly LastName = 'Last Name';
  public static readonly PersonUpdatedMessage = 'Person updated successfully!';
  public static readonly InvalidName = 'Only alpha characters, hyphens, and spaces are allowed.';
  public static readonly InvalidSuffix = ' Only alpha characters, hyphens, and spaces are allowed.';
  public static readonly PleaseSelect = 'Please select atleast one ';
  public static readonly CellPhoneTitle = 'Cell phone';
  public static readonly HomePhoneTitle = 'Home phone';
  public static readonly MailingAddressTitle = 'Mailing address';
  public static readonly PhysicalAddressTitle = 'Physical address';
  public static readonly CellPhone = 'cell phone';
  public static readonly HomePhone = 'home phone';
  public static readonly MailingAddress = 'mailing address';
  public static readonly PhysicalAddress = 'physical address';
  public static readonly PrimaryCellPhone = 'primary cell phone';
  public static readonly PrimaryHomePhone = 'primary home phone';
  public static readonly PrimaryMailingAddress = 'primary mailing address';
  public static readonly PrimaryPhysicalAddress = 'primary physical address';
  public static readonly Unauthorized = 'You do not have access to perform this functionality';
  public static readonly UnappliedPaymentDetail = 'UNAPPLIED PAYMENT DETAIL';
  public static readonly Yes = 'Yes';
  public static readonly System = 'System';
  public static readonly SystemUpdate = 'System update';
  // eslint-disable-next-line max-len
  public static readonly NonbillableRateMsg = 'BCNs for this service will not be displayed during Create Claims process but will show as “Non-Billable” in Transaction Log. Create Adjustments to update this bill rate on existing shifts.';
  public static readonly TerritoryPopup = 'Territory';
  public static readonly SelectTerritory = 'Please select territory';
  public static readonly SelectStatus = 'Please select status';
  public static readonly SelectActiveTerritory = 'Please select an active territory';
  public static readonly pageSize = 100;
  public static readonly maxPageSize = 1000;

  // People search
  public static readonly MaxSearchRecords = 100;

  // kendo grid status column
  public static readonly Active = 'Active';
  public static readonly Inactive = 'Inactive';
  public static readonly Incomplete = 'Incomplete';
  public static readonly OnHold = 'OnHold';
  public static readonly Terminated = 'Terminated';

  // kendo grid PENDING value
  public static readonly PendingDetail = 'PENDING';
  public static readonly AddressError = 'Mailing and Physical addresses';
  public static readonly PhoneError = 'Home phone number';
  public static readonly SSNError = 'SSN';
  public static readonly BirtDateError = 'Date of Birth';
  public static readonly EmailError = 'Email';
  public static readonly BreakLine = '<br>';
  public static ErrorMessage = 'Following field(s) are required for other persontype(s) associated with this person';
  public static readonly Ellipses = '...';

  // LCN Status error message
  public static readonly LCNStatusDateError = 'Event Date should be greater than or equal to current date for Reset Status';

  // common grid error messages
  public static CommonGridErrorMessages =
  {
    invalidDateSelected: 'Invalid date',
    dateNotInFiscalYear: 'End Date must be within fiscal year (before 6/30)',
    dateRangeOverlap: 'Date range overlaps another range',
    endDateLessThanOrEqualStartDate: 'End date should be later than start date.',
    endDateLessThanStartDate: 'End date should be later than or equal to start date.',
    exclusionReasonMissing: 'Select Exclusion Reason',
    incompleteSSN: 'SSN should be 9 digits.',
    invalidSSN: 'Please enter a valid SSN.',
    invalidDate: 'Please enter a valid date.',
    invalidDateMin: 'Please enter a valid date after 01/01/1900.',
    invalidTransactionDate: 'Please select a date between authorization summary start date and end date',
    invalidDateMax: 'Please enter a valid date before 12/31/9999.',
    invalidEmail: 'Please enter a valid email.',
    invalidPhone: 'Phone number should be 10 digits.',
    invalidZip: 'Please enter a valid zip code.',
    negativeOrZero: 'Please enter a positive number',
    negative: 'Please enter 0 or greater',
    isZero: 'Please enter a value other than 0',
    required: 'Required',
    temporaryWWLLessThanPermanentWWL: 'Temporary WWL Hours should be greater than Permanent WWL Hours.',
    temporaryWWLLessThanZero: 'Temporary WWL Hours should be greater than 0.',
    workWeekLimitStartDayInvalid: 'Invalid Start Date',
    workWeekLimitEndDayInvalid: 'Invalid End Date',
    duplicateAdjustmentReasonsBySameAdjustmentOption: 'Reason already exists',
    adjustmentReasonLength : 'Adjustment reason should be 50 characters',
    adjustmentReasonVisibleValidation : 'Please select atleast one visible option',
    adjustmentReasonRequired: 'Reason is required',

    // EVV Exception Reason constants
    evvExceptionReasonRequired: 'EVV Exception Reason is required',
    evvExceptionCodeRequired: 'EVV Exception Code is required',
    evvExceptionReasonLength : 'EVV Exception reason should be 50 characters',
    evvExceptionCodeLength : 'EVV Exception code should be 50 characters',
    duplicateEvvExceptionReason : 'EVV Exception Reason already exists',
    duplicateEvvExceptionCode : 'EVV Exception Code already exists',
    invalidText: 'Only alpha characters and spaces are allowed.',

    allocatedOverServiceAvailable: 'Allocated Units Exceed Service Code Available Units',
    allocatedOverCaregiverAvailable: 'Allocated Units Exceed Caregiver Available Units',
    caregiverNoAvailable: 'The Relationship Does Not Exist This Week',
    notOkToWork: 'Caregiver is not ok to work service code',
    notOkToDrive: 'Caregiver is not ok to drive',
    codesDescriptionLength : 'Description should not exceed 500 characters',
    duplicateResolutionReason : 'Duplicate Resolution and Reason mappings',
    u2v4Select: 'Please select either of checkboxes or both',
    qualificationCoursesValidation : 'Duplicate Course Name and Completed Date',
    hoursQuarters : 'Hours in quarter amounts only',
    unitsQuarters : 'Units in quarter amounts only',
    serviceCodeRelatedToAuth : 'Service Code is not of Auth Type',
    subRuleSequenceValidation : 'Sequence number already exist for qualification sub rule',
    subRuleQualificationValidation : 'Qualification and Pre-req already exist for qualification sub rule',
    statusPersonTypeActionsValidation : 'Actions already exist for person type.',
    statusPersonTypeReasonValidation : 'Reason already exist for person type.',
    negativeOrZeroSequence: 'Sequence number must be greater than zero'
  };

  // Max Time Out constants
  public static readonly maxHours = 23;
  public static readonly maxMinutes = 59;
  public static readonly maxSeconds = 59;

  // Auth detail statuses
  public static readonly authActive = 'active';
  public static readonly authHold = 'hold';
  public static readonly authInActive = 'inactive';

  // Configuration API
  public static readonly UnableToLoadFeatures = 'Unable to load features';
  public static readonly CompanyFeaturesStorageKey = 'CompanyFeatures';
  public static readonly CompanyConfigStorageKey = 'CompanyConfig';
  public static readonly CanImpersonateStorageKey = 'CanImpersonate';
  public static readonly CompanyCode = 'CompanyCode';

  // Irs Waiver Date constansts
  public static readonly Exemptions = 'Exemptions';
  public static readonly millisecondsInDay = 86400000;
  public static readonly millisecondsInSecond = 1000;
  public static readonly secondsInMinute = 60;
  public static readonly minutesInHour = 60;
  public static readonly hoursInDay = 24;

  // Status Date range constants
  public static readonly StatusDateRangePopup = 'Status Date Ranges';
  public static readonly StatusDateRangeOverlapError = 'Status date ranges should not overlap';

  // LiveIn Date range constants
  public static readonly LiveInDateRangePopup = 'Live-In Date Ranges';
  public static readonly LiveInDateRangeOverlapError = 'Live-In date ranges should not overlap';

  // Relationship Date range constants
  public static readonly RelationshipDateRangePopup = 'Relationship Date Ranges';
  public static readonly RelationshipDateOverlapError = 'Relationship date ranges should not overlap';

  // CoPay Messages
  public static readonly CoPayTypeDeleteSuccess = 'CoPay(s) deleted successfully!';
  public static readonly CoPayTypeDuplicate = 'CoPay already exist for the current date and service code selection';
  public static readonly CoPayUpdatedSuccessfully = 'CoPay Updated Successfully';
  public static readonly CoPayTypeDeleteRecordError = 'Cannot delete unsaved CoPay';
  public static readonly CoPayHasAssociatedTransactionsError = 'Cannot delete copay record as it has associated transactions';
  public static readonly CoPayAmountLessError = 'CoPay amount must be greater than or equal to amount satisfied';
  public static readonly CopayPrevAmount = 'CopayPrevAmount';

  // overlap message
  public static readonly overlapFound = 'Overlap Found';

  // PSST attestation text
  public static readonly attestationBulletOne =
    'You should report Seattle Paid Sick and Safe Time (PSST) for hours worked inside Seattle city limits.';
  public static readonly attestationBulletTwo = 'PSST hours will be on your pay stub but are not an eligible benefit.';
  public static readonly attestationBulletThree = 'Your union PTO benefit is more beneficial to you.';

  // Currency formatting
  public static readonly englishUs = 'en-US';
  public static readonly currencyFormatter = { // $##.##
    style: 'currency',
    currency: 'USD',
  };
  public static readonly currencyFormatterLong = { // $##.####
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 4,
    maximumFractionDigits: 4
  };

  // Time Spans
  public static readonly maxTimeStampValue = '23:59';
  public static readonly maxTimeStampWithSecondsValue = '23:59:59';
  public static readonly maxDisplayTimeValue = 24.0;
  public static readonly maxInputTimeValue = '23.98';

  // Auth Allocation Error Names
  public static readonly notOkToDrive = 'notOkToDrive';
  public static readonly notOkToWork = 'notOkToWork';
  public static readonly invalidhours = 'invalidhours';

  // Utex Error Names
  public static readonly duplicateResolutionReason = 'duplicateResolutionReasonMappings';

  // DateTime min date constant
  public static readonly minSmallDate: Date = new Date(1900, 0, 1); // 01/01/1900
  public static readonly maxSmallDate: Date = new Date(2079, 5, 6); // 06/06/2079
  public static readonly defaultEndDate: Date =
    new Date(2078, 11, 31); // 12/31/2078 BAs have chosen this date as the desired default end date
  public static readonly minSmallDateTime: Date = new Date(1900, 0, 1, 0, 0, 0);
  public static readonly maxSmallDateTime: Date = new Date(2079, 5, 6, 23, 59, 59);
  public static readonly maxAuthDate: Date = new Date(2079, 6, 6, 0, 0, 0, 0);

  //Button Tooltip Constants
  public static readonly tooltipDelay = 1000;

  // Common Components
  public static readonly continue = 'Continue';
  public static readonly save = 'Save';
  public static readonly update = 'Update';
  public static readonly confirm = 'Confirm';
  public static readonly open = 'Open';
  public static readonly close = 'Close';
  public static readonly cancel = 'Cancel';
  public static readonly delete = 'Delete';
  public static readonly yes = 'Yes';
  public static readonly no = 'No';
  public static readonly ok = 'OK';
  public static readonly continueMessage = 'Are you sure you want to continue?';
  public static readonly saveMessage = 'Would you like to save and continue?';
  public static readonly confirmMessage = 'Are you sure?';
  public static readonly cancelMessage = 'Are you sure you want to cancel?';
  public static readonly deleteMessage = 'Are you sure you want to delete the selected record?';
  public static readonly deleteMultipleMessageStub = 'Are you sure you want to delete';
  public static readonly unsavedChangesMessage = 'This action will lose your unsaved changes, do you want to continue?';
  public static readonly adjustmentReasonCreated = 'Adjustment reason created successfully!';
  public static readonly adjustmentReasonUpdated = 'Adjustment reason updated successfully!';
  public static readonly adjustmentReasonDeleted = 'Adjustment reason(s) deleted successfully!';

  // Export Components
  public static readonly EDITEXPORTAPPROVEDPAYMENTBATCH = 'exportpaymentbatch';
  public static readonly EXPORTCAREERLOGHOURS = 'ExportCareerHourLog';
  public static readonly EXPORTCLAIMBATCH = 'exportclaimbatch';
  public static readonly EXPORTCLAIMSSUMMARY = 'exportclaimssummary';
  public static readonly EXPORTCLAIMBATCHEXCLUDEDVOIDEDCLAIMS = 'exportclaimbatchexcludedvoidedclaims';
  public static readonly EXPORTCLAIMBATCHGLXML = 'exportclaimbatchglxml';
  public static readonly EXPORTCLAIMTEMPLATE = 'exportclaimtemplate';
  public static readonly EXPORTPAYMENTBATCHORPHANDETAILS = 'exportpaymentbatchorphandetails';
  public static readonly EXPORTPAYROLLEXCLUDEDRECORDS = 'exportpayrollexcludedrecords';
  public static readonly EXPORTPAYROLLREVIEWDETAILS = 'exportpayrollreviewdetails';
  public static readonly EXPORTPAYROLLTRANSACTIONRECORDS = 'exportpayrolltransactionrecords';
  public static readonly EXPORTTRANSACTIONLOGHISTORY = 'exporttransactionloghistory';
  public static readonly EXPORTLCNSTATUSADJUSTMENTUPDATE = 'exportlcnstatusadjustmentupdate';
  public static readonly EXPORTCCNUPDATE = 'ExportCCNUpdate';

  //EVV Exception Reason Code Constants
  public static readonly programNames = 'programNames';
  public static readonly evvExceptionReasonTitle = 'EVV Exception Reasons';
  public static readonly programsSelectedString = ' Program(s) Selected';
  public static readonly scrollable = 'scrollable';

  // Search Field Constants
  public static inlineSearchButton = false;
  public static searchFieldsPerRow = 4;

  public static readonly activeLabel = 'Active';

  // Actions
  public static readonly actions = {
    v4StopTimeEntry : 'V4 Stop Time Entry',
    v4HideRelationship : 'V4 Hide Relationship',
    careTeamPlanStopTimeEntry : 'Care Team Plan Stop Time Entry',
    u2StopTimeEntry : 'U2 Stop Time Entry',
    celltrakStopTimeEntry : 'Celltrak Stop Time Entry',
    suspendTimeInUtex : 'Suspend Time in UTEX'
  };

  //Employer AND EOR Creation Constants
  public static readonly ssnExists = 'SSN must be Unique';
  public static readonly eorSaveNotAllowedMessage = 'EOR Person failed to create! You cannot create EOR Person' +
    ' with Employer record as default Employer is being set up for this company';
  public static readonly eorAlreadyExists = 'EOR Person with this SSN/ITIN already exists';
}

