import { UserRoleModel } from '../services/u2api.services';
import { UserRoleDto } from '../services/usersapi/usersapi.services';

export class AddUsersRoleTransform {
  static transformInput(userRoleModel: UserRoleModel): AddUsersRoleApiRequest[] {
    const allRequest: AddUsersRoleApiRequest[] = [];
    userRoleModel['userObjectIds']?.forEach(userObjectId => {
      const addUserRoleRequest = new AddUsersRoleApiRequest();
      addUserRoleRequest.userId = userObjectId;
      addUserRoleRequest.body = [
        new UserRoleDto({
          roleId: userRoleModel.roleId,
          isPrimary: false,
          roleName: undefined,
        }),
      ];
      allRequest.push(addUserRoleRequest);
    });
    return allRequest;
  }
}
export class AddUsersRoleApiRequest {
  userId: string;
  body?: UserRoleDto[];
}
