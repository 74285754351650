import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizeService {
  private _isUnauthorized = false;

  get isUnauthorized() {
    return this._isUnauthorized;
  }

  setUnauthorize() {
    this._isUnauthorized = true;
  }
}
