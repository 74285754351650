import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'common-loading-indicator',
  template: `
    <span *ngIf="show" class="bring-to-front k-loading-image page-loading-bg"></span>
    `,
  styles: [
  ]
})
export class CommonLoadingIndicatorComponent {
  @Input()
  get show(): boolean { return this._show; }
  set show(value: boolean) {
    this._show = value;
  }
  private _show = true;

  public environment = '';
  public shift = false;
  public ctrl = false;
  public end = false;

  constructor() {
    this.environment = environment.envName;
  }
}
