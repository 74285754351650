import { RoleIdDto } from '../services/rolesapi/rolesapi.services';

export class DeleteUserRolesTransform {
  static transformInput(userIds: string[], roleId: string): UnassignUsersRoleApiRequest[] | undefined {
    const allRequest: UnassignUsersRoleApiRequest[] = [];
    userIds.forEach(userObjectId => {
      const unassignUserRoleRequest = new UnassignUsersRoleApiRequest();
      unassignUserRoleRequest.userId = userObjectId;
      unassignUserRoleRequest.body = [
        new RoleIdDto({
          roleId: Number(roleId),
        }),
      ];
      allRequest.push(unassignUserRoleRequest);
    });
    return allRequest;
  }
}
export class UnassignUsersRoleApiRequest {
  userId: string;
  body?: RoleIdDto[];
}
