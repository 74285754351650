import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IBreadCrumb } from '../core/Interface/IBreadcrumb';
import { CommonConstants } from '../shared/constants/common-constants';
import { StorageConstants } from '../shared/constants/storage-constants';
import { BreadcrumbCommonService } from '../shared/services/breadcrumb-common.service';
import { Custombreadcrumbmodel } from '../shared/services/models/custombreadcrumbmodel';
import { CommonFunctions } from '../shared/utilities/common-functions';
import { BreadCrumbData } from './../core/Interface/Breadcrumb';
import { AppConstants } from './../core/appConstant';
import { AppInsightsService } from './../core/services/app-insights.service';


@Component({
  selector: 'CDMS-Portal-U2-main-breadcrumb',
  templateUrl: './main-breadcrumb.component.html',
  styleUrls: ['./main-breadcrumb.component.scss'],
})

export class MainBreadcrumbComponent implements OnInit, AfterViewInit {


  public breadcrumbs: IBreadCrumb[];
  public title: string;
  public isEmptyRoute = true;
  public IsCustomBreadCrumb = false;
  public breadCrumbRendered = false;
  public CustomBreadCrumbLink: string;
  public CustomBreadCrumbText: string;
  public data: BreadCrumbData = new BreadCrumbData();
  param = '';
  public pageViewTitle = '';
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breadcrumbCommonService: BreadcrumbCommonService,
    private appInsightsService: AppInsightsService,
    private commonFunctions: CommonFunctions
  ) {
    this.breadcrumbs = [];
  }


  ngAfterViewInit(): void {
    const redirectUrl = localStorage.getItem(StorageConstants.RedirectUri);
    if (this.activatedRoute.snapshot['_routerState'].url === '/' || this.activatedRoute.snapshot['_routerState'].url === '') {
      if (redirectUrl) {
        const redirectUrilength = (window.location.origin + '/#/').length;
        const uri = redirectUrl.substr(redirectUrilength, redirectUrl.length);
        if (uri === '') {
          this.emptyRouteNavigation();
        } else {
          this.redirectToUrl(uri);
        }
      } else {
        this.emptyRouteNavigation();
      }
    }
  }
  redirectToUrl(currentUrl: string, navigateToUrl: boolean = true) {
    const linkUrls = Array.from(document.querySelectorAll('[data-url]'));
    let routeExists = false;
    if (linkUrls && linkUrls.length > 0) {
      linkUrls.forEach(x => {
        const uri = x.getAttribute('data-url');
        if (currentUrl.indexOf(uri) > -1) {
          routeExists = true;
          if (navigateToUrl) {
            (<HTMLElement>x).click();
          }
          const parentNode = <HTMLElement>x.parentNode.parentNode;
          if (this.hasClass(parentNode, 'has-child')) {
            if (this.hasClass(parentNode.children[1].children[1], 'collapsed')) {
              (<HTMLElement>parentNode.children[1].children[1]).click();
            }
          } else {
            if (this.hasClass(parentNode.children[0], 'collapsed')) {
              (<HTMLElement>parentNode.children[0]).click();
            }
          }
          if (parentNode.parentNode) {
            if (this.hasClass((<HTMLElement>parentNode.parentNode.parentNode).children[0], 'collapsed')) {
              (<HTMLElement>(<HTMLElement>parentNode.parentNode.parentNode).children[0]).click();
            }
          }
        }
      });
    }
    if (!routeExists && AppConstants.anonymousRoutes.indexOf(currentUrl) <= -1) {
      this.emptyRouteNavigation();
    } else {
      if (navigateToUrl) {
        this.router.navigateByUrl(currentUrl);
      }
    }
  }

  ngOnInit() {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_Parent = 'parentbd';
    const ROUTE_DATA_ParentURL = 'parentURL';
    const ROUTE_DATA_Title = 'title';

      window.addEventListener('storage', (event) => {
        if (event.key === CommonConstants.renderBreadcrumbForIE) {
          this.renderBreadcrumb();
        } else if (!this.breadCrumbRendered && this.commonFunctions.getBrowserName() === 'ie') {
          this.renderBreadcrumb();
          this.breadCrumbRendered = true;
        }
      });
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.renderBreadcrumb();
    });

    this.breadcrumbCommonService.getChange.subscribe((data: Custombreadcrumbmodel) => {
      this.IsCustomBreadCrumb = data.IsCustomBreadCrumb;
      this.CustomBreadCrumbText = data.CustomBreadCrumbText;
      this.CustomBreadCrumbLink = data.CustomBreadCrumbLink;
    });
  }


  private renderBreadcrumb() {
    if (this.activatedRoute.snapshot['_routerState'].url !== '/') {
      this.data = undefined;
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      this.appInsightsService.logPageView(this.pageViewTitle, document.URL);
      const redirectUrl = localStorage.getItem(StorageConstants.RedirectUri);
      if (redirectUrl) {
        localStorage.removeItem(StorageConstants.RedirectUri);
      }
      const locationHref = window.location.href;
      const redirectUrilength = (window.location.origin + '/#/').length;
      const uri = locationHref.substr(redirectUrilength, locationHref.length);
      this.redirectToUrl(uri, false);
    }
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';
    const ROUTE_DATA_Parent = 'parentbd';
    const ROUTE_DATA_ParentURL = 'parentURL';
    const ROUTE_DATA_Title = 'title';
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }


    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (child.snapshot.params['editAuthId']) {
        this.param = child.snapshot.params['editAuthId'];
      } else {
        this.param = undefined;
      }
      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;
      this.data = <BreadCrumbData>child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      this.data.title = child.snapshot.data[ROUTE_DATA_Title];
      this.pageViewTitle = child.snapshot.data[ROUTE_DATA_Title];
      if (this.param) {
        this.data.title = this.data.title + ' / ' + this.param;
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

  getParent(urlPath: string): string {
    return urlPath.substr(2, urlPath.indexOf('/'));
  }

  handleParentClick(parentbd: string) {
    const parentElement = document.getElementById(parentbd.replace(/\s/g, '').toLowerCase());
    if (parentElement !== null) {
      let liElements = Array.prototype.slice.call(parentElement.getElementsByTagName('li'));
      liElements = liElements ? liElements.filter(liEl => !this.hasClass(liEl, 'd-none')) : [];
      if (liElements.length > 0) {
        if (this.hasClass(liElements[0], 'has-child')) {
          let childelemnts = Array.prototype.slice.call(liElements[0].getElementsByTagName('li'));
          childelemnts = childelemnts ? childelemnts.filter(chEl => !this.hasClass(chEl, 'd-none')) : [];
          if (childelemnts.length > 0) {
            if (this.hasClass(parentElement.parentNode.childNodes[0], 'collapsed')) {
              (<HTMLElement>parentElement.parentNode.childNodes[0]).click();
            }
            if (this.hasClass(liElements[0], 'collapsed')) {
              (<HTMLElement>liElements[0]).click();
            }
            if (this.hasClass(liElements[0].childNodes[0].childNodes[1], 'collapsed')) {
              (<HTMLElement>(<HTMLElement>(<HTMLElement>liElements[0]).childNodes[0]).childNodes[1]).click();
            }
            childelemnts[0].click();
            return;
          }
        } else {
          if (this.hasClass(parentElement.parentNode.childNodes[0], 'collapsed')) {
            (<HTMLElement>parentElement.parentNode.childNodes[0]).click();
          }
          liElements[0].click();
          return;
        }
        return;
      }
    }
  }

  emptyRouteNavigation() {
    const rootNode = document.getElementsByClassName('list-unstyled');
    if (rootNode.length > 1) {
      let liElements: any = '';
      let i = 1;
      for (i = 1; i < rootNode.length; i++) {
        liElements = Array.prototype.slice.call(rootNode[i].getElementsByTagName('li'));
        liElements = liElements ? liElements.filter(liEl => !this.hasClass(liEl, 'd-none')) : [];
        if (liElements.length > 0) {
          if (this.hasClass(liElements[0], 'has-child')) {
            let childelemnts = Array.prototype.slice.call(liElements[0].getElementsByTagName('li'));
            childelemnts = childelemnts ? childelemnts.filter(chEl => !this.hasClass(chEl, 'd-none')) : [];
            if (childelemnts.length > 0) {
              const firstChild = (<HTMLElement>rootNode[i].parentNode.childNodes[0]);
              if (firstChild) {
                firstChild.click();
              }
              const liItem = (<HTMLElement>liElements[0]);
              if (liItem) {
                const liFirstChild = (<HTMLElement>liItem.childNodes[0]);
                if (liFirstChild) {
                  const secondNode = (<HTMLElement>(liFirstChild).childNodes[1]);
                  if (secondNode) {
                    secondNode.click();
                  }
                }
              }
              const routName = (<HTMLElement>childelemnts[0]).getAttribute('data-url');
              if (routName && routName !== '' && routName !== null) {
                this.router.navigateByUrl('/' + routName);
              }
              return;
            }
          } else {
            (<HTMLElement>rootNode[i].parentNode.childNodes[0]).click();
            const routName = (<HTMLElement>liElements[0]).getAttribute('data-url');
            if (routName && routName !== '' && routName !== null) {
              this.router.navigateByUrl('/' + routName);
            }
            return;
          }
        }
      }
    }
  }
  hasClass(element, className) {
    if (element) {
      return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
    }
  }

  public CustomBreadCrumbClick() {
    this.router.navigateByUrl(this.CustomBreadCrumbLink);
  }

}
