import { map, Observable } from 'rxjs';
import { IResponse, RoleIdDto } from '../services/rolesapi/rolesapi.services';
import { ResponseModel } from '../services/u2api.services';

export class DeleteRolesTransform {
  static transformInput(roleIds?: string | undefined): RoleIdDto[] | undefined {
    if (roleIds === null || roleIds === undefined) {
      return undefined;
    } else {
      const ids = roleIds.split(',').map(Number);
      return ids.map(id => new RoleIdDto({ roleId: id }));
    }
  }

  static transformOutput(observableResponse: Observable<IResponse>): Observable<ResponseModel> {
    // Request was successfull if response is not null or undefined as a 204 is returned
    return observableResponse.pipe(
      map(response => {
        const responseModel = new ResponseModel();
        responseModel.isSuccess = response !== null && response !== undefined;
        return responseModel;
      }),
    );
  }
}
