export class FeatureConstants {
    public static readonly CCH = 'Global.Company.People.CCH';
    public static readonly Qualifications = 'Global.Company.People.Qualifications';
    public static readonly Notifications = 'Global.Company.NotificationHub.Notifications';
    public static readonly WWL = 'Global.Company.People.WWL';
    public static readonly Tasks = 'Global.Company.People.Tasks';
    public static readonly ServiceCodeTasks = 'Global.Company.People.ServiceCode.Tasks';
    public static readonly AuthAllocation = 'Global.Company.Authorization.AuthAllocation';
    public static readonly GHS = 'Global.Company.Shift.GHS';
    public static readonly PTO = 'Global.Company.People.PTO';
    public static readonly InternalAuth = 'U2.Company.Authorization.InternalAuth';
    public static readonly EmployerPayRates  = 'U2.Company.People.EmployerPayRates';
    public static readonly EmployerDeductionDelete  = 'U2.Company.People.EmployerDeductionDelete';
    public static readonly ShiftSubmitCountsAsApproved = 'Global.Company.Shift.ShiftSubmitCountsAsApproved';
    public static readonly UseNewIngestionFlow = 'Global.Company.Shift.UseNewIngestionFlow';
    public static readonly WaiverCodeSingleLineEdit  = 'U2.Company.Authorization.WaiverCodeSingleLineEdit';
    public static readonly WaiverCodeMultiLineEdit = 'U2.Company.Authorization.WaiverCodeMultiLineEdit';
    public static readonly PSST = 'Global.Company.People.PSST';
    public static readonly CoEmploymentAutoEmployerRelationship = 'U2.Company.People.CoEmploymentAutoEmployerRelationship';
    public static readonly CoEmploymentEmployerOfRecordDisabled = 'U2.Company.People.CoEmploymentEmployerOfRecordDisabled';
    public static readonly CaregiverDemographicsConfirmation = 'Global.Company.People.CaregiverDemographicsConfirmation';
    public static readonly CGParticipantRelationshipConfirmation = 'Global.Company.People.CaregiverParticipantRelationshipConfirmation';
    public static readonly CaregiverDifficultOfCareConfirmation = 'Global.Company.People.CaregiverDifficultOfCareConfirmation';
    public static readonly CaregiverFICAConfirmation = 'Global.Company.People.CaregiverFICAConfirmation';
    public static readonly CaregiverQualificationsConfirmation = 'Global.Company.People.CaregiverQualificationsConfirmation';
    public static readonly CaregiverBackgroundCheckConfirmation = 'Global.Company.People.CaregiverBackgroundCheckConfirmation';
    public static readonly CGPTReqRelConfirmation = 'Global.Company.People.CaregiverParticipantRequestRelationConfirmation';
    public static readonly PayrollBatchExport = 'U2.Company.Payroll.PreviewBatch.Export';
    public static readonly PayrollReviewExport = 'U2.Company.Payroll.ReviewDetails.Export';
    public static readonly CaregiverCoordinatorRelationship = 'Global.Company.People.CaregiverCoordinatorRelationship';
    public static readonly ParticipantCoordinatorRelationship = 'Global.Company.People.ParticipantCoordinatorRelationship';
    public static readonly AdminTimeCreation = 'Global.Company.Payroll.AdminTimeCreation';
    public static readonly AdminTimeAdjustment = 'U2.Company.Billing.AdminTimeAdjustment';
    public static readonly LowerTemporaryWWL = 'U2.Company.People.LowerTemporaryWWL';
    public static readonly Status = 'Global.Company.People.Status';
    public static readonly DisplayExternalKeys = 'U2.Company.People.DisplayExternalKeys';
    public static readonly LowerTemporaryWwl = 'U2.Company.People.LowerTemporaryWWL';
    public static readonly NetNegativeExclusion = 'Global.Company.Payroll.NetNegativeExclusion';
    public static readonly ShowFamilial79f = 'Global.Company.People.ShowFamilial79f';
    public static readonly ShowCGPTCreatButton = 'Global.Company.People.ShowCgProspectCreate';
    public static readonly SyncCareTeamToCellTrak = 'Global.Company.People.SyncCareTeamToCellTrak';
    public static readonly ShowCaregiverParticipantAttestations ='Global.Company.People.ShowCaregiverParticipantAttestations';
    public static readonly UnitBased = 'U2.Company.Authorization.UnitBased';
    public static readonly DollarBased = 'U2.Company.Authorization.DollarBased';
    public static readonly isHashingEnabled = 'U2.Company.Claims.ClaimHashes';
    public static readonly CaregiverCourses = 'U2.Company.People.CaregiverCourses';
    public static readonly ServiceCodeUsageLimit = 'U2.Company.Maintenance.ServiceCodeUsageLimit';
    public static readonly SSNValidation = 'Global.Company.People.SSNValidation';
    public static readonly SSNStatus = 'Global.Company.People.SSNStatus';
    public static readonly isSSNValidationOverrideEnabled = 'U2.Company.People.SSNValidationOverrideEnabled';
    public static readonly VASickTime = 'Global.Company.Authorization.VASickTime';
    public static readonly ShowCaregiverQualRolesOnCreate = 'U2.Company.People.ShowCaregiverQualRolesOnCreate';
    public static readonly U2LogErrorResponse = 'U2.Company.Devops.U2LogErrorResponse';
    public static readonly EvvNewShiftService = 'EVV.Company.Shift.UseNewShiftService';
    public static readonly DisassociateButtonEnabled = 'U2.Company.People.DisassociateButtonEnabled';
    public static readonly VAOvertimeBilling = 'U2.Company.Billing.VAOvertimeBilling';
    public static readonly U2NewShiftService = 'U2.Company.Shift.UseNewShiftService';
    public static readonly OKToWorkDeterminedByCaregiverRoleTypeForParticipant
    = 'U2.Company.People.OKToWorkDeterminedByCaregiverRoleTypeForParticipant';
    public static readonly TrainingServiceCodeExtensionUponQualificationExpiry
    = 'U2.Company.People.TrainingServiceCodeExtensionUponQualificationExpiry';
    public static readonly UserCreationToolScreen = 'U2.Company.People.UserCreationToolScreen';
    public static readonly UseIamApi = 'U2.Company.IAM.UseIamApi';
    public static readonly UseDddApi = 'U2.Company.People.UseDddApi';
    public static readonly DisableParticipantTasks = 'Global.Company.People.DisableParticipantTasks';
    public static readonly ClaimHashesUseProgramConfigByDates = 'U2.Company.Billing.ClaimHashesUseProgramConfigByDates';
    public static readonly FEATransfer = 'U2.Company.People.FEATransfer';
    public static readonly PayRatesInjector = 'U2.Company.People.PayRatesInjector';
    public static readonly ProspectiveRelationshipReviewAttestations = 'U2.Company.People.ProspectiveRelationshipReviewAttestations';
}
