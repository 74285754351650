import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IController } from 'src/app/controllers/interface/IController.interface';
import { PeopleFunctionService } from 'src/app/core/services/peopleapi/peoplefunctionapi.services';
import { IErrorRuleDto, ILookupValueDto, UTEXService } from 'src/app/core/services/utexapi/utexapi.services';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';

@Injectable({
  providedIn: 'root',
})

export class UTEXErrorCodeService implements OnDestroy, IController {
  private _entity: BehaviorSubject<ILookupValueDto[]> = new BehaviorSubject<ILookupValueDto[]>(
    undefined
  );
  private _parameters: BehaviorSubject<any> = new BehaviorSubject<any>(
    [2]
  );
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly _destroying$ = new Subject<void>();

  constructor(
    public toastr: ToastrService,
    public apiService: PeopleFunctionService,
    public commonFunctions: CommonFunctions,
    public utexService: UTEXService,
  ) {}

  async search(data?: any) {
    if (data) {
      this._parameters?.next(data);
    } else if (this._parameters.getValue()) {
      data = this._parameters.getValue();
    } else {
      return;
    }
    data = this.commonFunctions.removeNull(data);

    this._load.next(true);

    this.utexService.getErrorRules().pipe(
      take(1),
      takeUntil(this._destroying$)
    ).subscribe((data: IErrorRuleDto[]) => {
      const errorRules = data;
      this.utexService.getLookupValues([2]).pipe(
        take(1),
        takeUntil(this._destroying$)
      ).subscribe({
        next: (dataLookup: ILookupValueDto[]) => {
          if (dataLookup) {
            dataLookup.forEach((ec, idx) => {
              dataLookup[idx].value = ec.value + ' ' + errorRules.find(e => ec.valueId === e.errorCodeId).errorDescription;
            });
            this._entity.next(dataLookup);
          }
        },
        error: error => {
          this.commonFunctions.handleErrorResponse(error, this.toastr);
        },
        complete: () => {
          this._load.next(false);
        },
      });
    });

    this.utexService.getLookupValues(data)
    .pipe(
        take(1),
        takeUntil(this._destroying$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this._entity.next(res);
        }
      },
      error: error => {
        this.commonFunctions.handleErrorResponse(error, this.toastr);
      },
      complete: () => {
        this._load.next(false);
      },
    });

  }

  public fetchData(override = false): Observable<ILookupValueDto[]> {
    if (this._entity.getValue() === undefined || this._entity.getValue().length === 0 || override) {
      this.search();
    }
    return this._entity;
  }

  public getSearchParameters(): Observable<any> {
    return this._parameters;
  }

  public setSearchParameters(data: any) {
    this._parameters?.next(data);
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this.clearEntity();
    this.clearParameters();
  }

  public clearEntity(): void {
    this._entity.next(undefined);
  }

  public clearParameters(): void {
    this._parameters?.next([2]);
  }

  public clearLoad(): void {
    this._load.next(false);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
