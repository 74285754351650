import { map, Observable } from 'rxjs';
import { ResponseModel, RoleModel } from '../services/u2api.services';
import { RolesResponse, UpsertRoleCommand } from '../services/rolesapi/rolesapi.services';
import { TransformUtilities } from './utilities.transform';
import { IamWrapperConstants } from '../iamWrapperConstants';

export class UpsertRoleTransform {
  static transformInput(roleModel: RoleModel): UpsertRoleCommand {
    const transformedRole = new UpsertRoleCommand({
      roleId: roleModel.roleId,
      roleName: roleModel.roleName,
      applicationName: IamWrapperConstants.U2ApplicationName,
      canImpersonate: roleModel.canImpersonate,
      isInternal: false,
    });
    transformedRole.roleCompanies = TransformUtilities.extractCompanyNames(roleModel.companies);
    transformedRole.rolePermissions = TransformUtilities.mapScreensToResourcePermissions(roleModel.screens);
    return transformedRole;
  }

  static transformOutput(observableResponse: Observable<RolesResponse>): Observable<ResponseModel> {
    return observableResponse.pipe(
      map(response => {
        if (response?.roleId !== null || response?.roleId !== undefined) {
          return new ResponseModel({ isSuccess: true, message: IamWrapperConstants.RoleUpdateSuccess });
        } else {
          return new ResponseModel({ isSuccess: false, message: IamWrapperConstants.RoleUpdateFailure });
        }
      }),
    );
  }
}
