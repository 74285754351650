import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { IamWrapperService } from '../services/iam-wrapper.service';
import { CommonFunctions } from 'src/app/shared/utilities/common-functions';
import { finalize, of, tap } from 'rxjs';
import { UnauthorizeService } from '../services/unauthorize.service';
import { environment } from 'src/environments/environment';

export const CompanyContextResolver: ResolveFn<any> = () => getResolverData();

const getResolverData = (
  iamWrapperService = inject(IamWrapperService),
  commonFunctions = inject(CommonFunctions),
  unauthorizeService = inject(UnauthorizeService),
) => {
  if (environment.compatibility.useIamApis && unauthorizeService.isUnauthorized) {
    return of([]);
  }

  commonFunctions.showLoading();
  return iamWrapperService.getUserAccessedCompanies().pipe(
    tap(userAccessedCompanies => {
      if (userAccessedCompanies) {
        iamWrapperService.UserAccessedCompany = userAccessedCompanies;
        const existingCompanyCode = sessionStorage.getItem('CompanyCode');
        if (!existingCompanyCode) {
          const [company] = userAccessedCompanies;
          if (company) {
            const companyName = company.companyName;
            sessionStorage.setItem('CompanyCode', companyName);
          }
        }
      }
    }),
    finalize(() => commonFunctions.hideLoading()),
  );
};
