import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IScreen } from '../models/screen';
import { ScreenLookup } from './../models/screen-lookup';

@Injectable({
    providedIn: 'root'
})
export class PortalUserService {

    url: string;
    constructor(private http: HttpClient, private router: Router) {
        this.url = environment.apiUrl;
    }

    checkAcessRights(url: string): boolean {
        let actRoute = url;
        if (actRoute !== '' && actRoute !== '/' && actRoute !== '/unauthorized') {
            let hasAccess = false;
            const userAcessFromSession = sessionStorage.getItem('userAccess');
            if (userAcessFromSession) {
                const accessCheckString = atob(userAcessFromSession);
                if (accessCheckString !== '' && accessCheckString !== ' ') {
                    while (actRoute.charAt(0) === '/') {
                        actRoute = actRoute.substr(1);
                    }
                    const arryAccess = accessCheckString.split(',');
                    for (let i = 0; i < arryAccess.length; i++) {
                        if (arryAccess[i] !== '') {
                            if (actRoute.search(arryAccess[i]) !== -1) {
                                hasAccess = true;
                                break;
                            }
                        }
                    }
                    if (!hasAccess) {
                        this.router.navigateByUrl('/unauthorized');
                        return false;
                    }
                    return true;
                }
            }
        } else {
            return true;
        }
    }

    checkAccessWithPermission(screenCode: string, permission: string): boolean {
        const permissonsFromStorage = sessionStorage.getItem('screenpermssions');
        if (permission !== '' && permissonsFromStorage) {
            const permissionArry = <Array<IScreen>>JSON.parse(atob(permissonsFromStorage));
            if (permissionArry) {
                const screenPermissions = permissionArry.filter(x => x.screenCode === screenCode);
                if (screenPermissions && screenPermissions.length > 0) {
                    const permissionList = screenPermissions[0].permissions.filter(x => x.permissionName === permission);
                    if (permissionList) {
                        return permissionList.length > 0;
                    }
                }
            }
        }
        return false;
    }

    checkAccessWithPermissionLength(screenCode: string, permission: string): number {
        const permissonsFromStorage = sessionStorage.getItem('screenpermssions');
        if (permission !== '' && permission && permissonsFromStorage) {
            const permissionArry = <Array<IScreen>>JSON.parse(atob(permissonsFromStorage));
            if (permissionArry) {
                const screenPermissions = permissionArry.filter(x => x.screenCode === screenCode);
                if (screenPermissions && screenPermissions.length > 0) {
                    return  screenPermissions.length ;
                }
            }
        }
        return 0;
    }

    checkAccessWithPermissionByScreenId(screenId: number, permission: string): boolean {
        const screenObj = this.getScreensList().filter(x => x.Id === screenId);
        if (screenObj != null && screenObj.length > 0) {
            const screenCode = screenObj[0].Name;
            return this.checkAccessWithPermission(screenCode, permission);
        } else {
            return false;
        }
    }

    private getScreensList() {
      // Ids to not necessarily refer to screenIds in system
        const screenList: Array<ScreenLookup> = [
            {Id: 25, Name: 'ECM'},
            {Id: 27, Name: 'PAYRT'},
            {Id: 29, Name: 'SVCCD'},
            {Id: 30, Name: 'PGRM'},
            {Id: 31, Name: 'WOCDE'},
            {Id: 32, Name: 'DIAG'},
            {Id: 33, Name: 'PAYOR'},
            {Id: 34, Name: 'CEREL'},
            {Id: 56, Name: 'PTSTP'},
            {Id: 68, Name: 'MPMAP'},
            {Id: 59, Name: 'BILCD'},
            {Id: 60, Name: 'SVCT'},
            {Id: 1316, Name: 'ADJRE'},
            {Id: 1326, Name: 'WOERT'}
        ];
        return screenList;
    }

}
